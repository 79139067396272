import React from 'react';
import {
  Col,
  Input,
  Row,
  Table,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Modal,
  Form,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import {
  getAanvragen,
  auth,
  getUser,
  getUsersCompany,
  createUserAccount,
  getUsers,
} from '../../helpers/firebase';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/nl';
import toast from 'react-hot-toast';
import { CSVLink, CSVDownload } from 'react-csv';
import Loading from '../../components/Loading';
import { editConfig, saveConfig } from '../../helpers/firebase2';

moment.locale('nl');
class Users extends React.Component {
  state = {
    loading: false,
    activeTab: 'companies',
    modal: false,
    users: [],
    agents: [],
    companies: [],
    admins: [],
    voornaam: '',
    achternaam: '',
    email: '',
    wachtwoord: '',
    bedrijfsnaam: '',
    owner: '',
    role: '',
    pricePanel: '',
    commission: '',
    percentage: '',
    config: {},
  };

  toggle = () => {
    this.setState({ modal: this.state.modal ? false : true });
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = await getUser();
    this.setState({ user: user });
    this.getUsers(user);
  };

  //   componentDidUpdate = async (prevProps) => {
  //     if (prevProps.match !== this.props.match) {
  //       let status = this.props.match.params.status
  //         ? this.props.match.params.status
  //         : false;

  //       this.setState({ status });
  //       await this.loadAanvragen(status);
  //     }
  //   };

  getCompany = (owner, list) => {
    return list.filter((a) => a.id === owner);
  };

  getUsers = async () => {
    const user = this.state.user;

    if (user.role === 'admin') {
      let list = await getUsers();
      list = list.map((a) => {
        if (a.owner) a.company = this.getCompany(a.owner, list)[0];
        // console.log(a);
        if (a.role === 'bedrijf') {
          if (a.id === 'RYoZR0D41BagayShcKgqrVkWjOD3') {
            saveConfig(a.config, 'u8aHplR2dNM3xxNTPw7aPaEqNxK2');
            this.setState({
              config: a.config,
            });
          }
          // console.log(a)
        }
        return a;
      });
      // if (user.role === "company") {
      //   list = await getUsersCompany(user.agents);
      //   this.setState({
      //     users: list.userCompanies,
      //     currentUser: user,
      //     companies: list.companies,
      //     loading: false,
      //   });
      //   return true;
      // }

      this.setState({
        users: list,
        currentUser: user,
        // companies: list.companies,
        // loading: false,
      });
      let companies = [];
      // let agents = [];
      // let admins = [];
      // let config = {};
      list.map((a, i) => {
        if (a.role === 'bedrijf') {
          companies.push(a);
          // if (a.id === "RYoZR0D41BagayShcKgqrVkWjOD3") {
          //   // console.log(a.config);
          //   // config = a.config;
          // }
        }
        //   if (a.role === "agent") agents.push(a);
        //   if (a.role === "admin") admins.push(a);
        //   if (a.id === "lhYri4hx3WTobxqcW8mtanicGp22") companies.push(a);
        //   if (a.id === "Fi4xkYzP2MeCxpeAfWONtsBq8Pk1") companies.push(a);
      });
      // // console.log(agents, "agents");

      this.setState({ companies, loading: false });
    } else {
      this.props.history.push('/');
    }
  };

  editConfig = (id) => {
    editConfig(id, this.state.config);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let obj = {
      voornaam: this.state.voornaam,
      achternaam: this.state.achternaam,
      email: this.state.email,
      wachtwoord: this.state.wachtwoord,
      role: this.state.role,
      owner: this.state.owner,
      createdAt: moment().format(),
      updatedAt: moment().format(),
      hideOld: true,
    };
    if (this.state.role === 'agent') {
      // obj.companyID = this.state.companyID;
      obj.bedrijfsnaam = this.state.companyID.bedrijfsnaam;
    }

    if (this.state.role === 'bedrijf') {
      obj.bedrijfsnaam = this.state.bedrijfsnaam;
      obj.config = {
        pricePanel: Number(this.state.pricePanel),
        pricePaneel: Number(this.state.pricePanel),
        commissionType: this.state.commission,
        percentage: Number(this.state.percentage),
        priceChange: {
          4: 120,
          5: 120,
          6: 110,
          7: 110,
          8: 105,
          9: 105,
          10: 100,
          11: 100,
          12: 100,
          13: 100,
          14: 97.9,
          15: 97.9,
          16: 97.9,
          17: 95.5,
          18: 95.5,
          19: 95.5,
          20: 92.7,
          21: 92.7,
          22: 92.1,
          23: 90.6,
          24: 90.6,
          25: 90.6,
          26: 89.3,
          27: 89.1,
          28: 89.1,
          29: 88,
          30: 87.9,
        },
      };
    }

    let valid = this.validatePassword(obj);

    console.log(obj, valid);
    if (valid.success) {
      console.log(obj);
      let result = await createUserAccount(obj);
      console.log(result);
      this.handleResult(result, obj);
    }
  };

  validatePassword = (p) => {
    let isValid = { success: true, msg: '' };

    if (p.wachtwoord.length < 8) {
      isValid.success = false;
      isValid.message = 'Password moet minimaal 8 tekens lang zijn.';
    }

    return isValid;
  };

  handleResult = (result, obj) => {
    console.log(result);
    if (result.success) {
      this.setState({
        modal: false,
      });
      this.getUsers();
      toast.success(`${obj.role} is aangemaakt`, {
        duration: 4000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        // icon: "👏",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#4caf50',
          secondary: '#fff',
        },

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
    }
    if (!result.success) {
      toast.error(
        'Er gaat iets fout probeer het opnieuw later nogmaals.',
        {
          duration: 4000,
          position: 'top-center',

          // Styling
          style: {},
          className: '',

          // Custom Icon
          // icon: "👏",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: '#b52d2d',
            secondary: '#fff',
          },

          // Aria
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        }
      );
    }
  };

  handleInput = (e) => {
    e.preventDefault();

    let id = e.target.id;
    let value = e.target.value;

    if (id === 'owner') {
      let company = this.state.companies.filter(
        (a) => a.id === value
      );
      this.setState({
        [id]: value,
        companyID: company[0],
      });
    } else {
      this.setState({
        [id]: value,
      });
    }
  };

  getCompanyName = (id) => {
    let companies = this.state.companies;

    let company = companies.filter((a) => a.id === id);

    if (company.length > 0) {
      return company[0].bedrijfsnaam;
    } else {
      return '';
    }
  };
  getAgentsList = (id) => {
    let agents = this.state.agents;

    let verkopers = agents.filter((a) => {
      if (a.owner === id) return a;
    });

    return verkopers;
  };

  saveconfig = async (config) => {
    let id = 'u8aHplR2dNM3xxNTPw7aPaEqNxK2';

    await saveConfig(config, id);
  };

  render() {
    const {
      activeTab,
      users,
      agents,
      companies,
      admins,
      loading,
      config,
    } = this.state;

    // console.log(config);

    return (
      <div className="content-container">
        <div className="table-block user-page">
          {loading ? (
            <div className="loading-container">
              <Loading />
            </div>
          ) : (
            <div className="row">
              <Col xs="12">
                <Row className="inner-row">
                  <Col>
                    {/* <div className="table-header">
                      <div
                        onClick={() => this.setState({ activeTab: "agents" })}
                        className={`table-tab ${
                          activeTab === "agents" ? "active" : ""
                        } agents`}
                      >
                        Verkopers
                      </div>
                      <div
                        onClick={() =>
                          this.setState({ activeTab: "companies" })
                        }
                        className={`table-tab ${
                          activeTab === "companies" ? "active" : ""
                        } companies`}
                      >
                        Bedrijven
                      </div>
                      <div
                        onClick={() => this.setState({ activeTab: "admins" })}
                        className={`table-tab ${
                          activeTab === "admins" ? "active" : ""
                        } admins`}
                      >
                        Admins
                      </div>
                    </div> */}
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.toggle}
                    >
                      Gebruiker toevoegen
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.getUsers}
                    >
                      Refresh
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Row className="agent-row">
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>Voornaam</th>
                      <th className="">Achternaam</th>
                      <th className="">E-mail</th>
                      <th className="">Rol</th>
                      <th className="">Bedrijf</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 &&
                      users.map((a, i) => {
                        return (
                          <tr key={i}>
                            <td>{a.voornaam}</td>
                            <td>{a.achternaam}</td>
                            <td>{a.email}</td>
                            <td>{a.role}</td>
                            <td>
                              {a.role === 'agent' &&
                                a.company.bedrijfsnaam}
                              {a.role === 'admin' && 'HelloZon'}
                              {a.role === 'bedrijf' && a.bedrijfsnaam}
                              {!a.role && 'HelloZon'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {}
              </Row>
              {activeTab === 'agents' && (
                <Row className="agent-row">
                  {agents.length > 0 &&
                    agents.map((a, i) => {
                      return (
                        <Col
                          className="mb-3"
                          key={i}
                          xs="6"
                          sm="3"
                          md="2"
                        >
                          <Card>
                            <CardBody>
                              <CardTitle tag="h5">
                                {a.voornaam} {a.achternaam}
                              </CardTitle>
                              <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                              >
                                {this.getCompanyName(a.owner)}
                              </CardSubtitle>
                              <CardText className="">
                                Prijs per paneel:{' '}
                                <span className="price">
                                  {a.config && a.config.pricePanel}
                                </span>
                              </CardText>
                              {/* <Button>Bekijken</Button> */}
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              )}

              {activeTab === 'companies' && (
                <Row className="agent-row">
                  {companies.length > 0 &&
                    companies.map((a, i) => {
                      let agentList = this.getAgentsList(a.id);
                      agentList = _.sortBy(agentList, ['voornaam']);

                      return (
                        <Col
                          className="mb-3"
                          key={i}
                          xs="6"
                          sm="3"
                          md="2"
                        >
                          <Card>
                            <CardBody>
                              <CardTitle
                                tag="h5"
                                onClick={() => this.editConfig(a.id)}
                              >
                                {a.bedrijfsnaam}
                              </CardTitle>
                              <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                              >
                                {a.voornaam} {a.achternaam}
                              </CardSubtitle>
                              <div>
                                <span className="card-item">
                                  <b>Contactgegevens:</b>
                                  <br />
                                  {a.email}
                                </span>
                                <span className="card-item">
                                  <b>Verkopers</b>
                                  <ol className="agent-list">
                                    {agentList.length > 0 &&
                                      agentList.map((b, index) => (
                                        <li key={index}>
                                          {b.voornaam}
                                        </li>
                                      ))}
                                  </ol>
                                </span>
                              </div>
                              {/* <Button>Button</Button> */}
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              )}

              {activeTab === 'admins' && (
                <Row className="agent-row">
                  {admins.length > 0 &&
                    admins.map((a, i) => (
                      <Col
                        className="mb-3"
                        key={i}
                        xs="6"
                        sm="3"
                        md="2"
                      >
                        <Card>
                          <CardBody>
                            <CardTitle
                              onClick={() => this.editConfig(a.id)}
                              tag="h5"
                            >
                              {a.voornaam}
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2 text-muted"
                              tag="h6"
                            >
                              {a.company}
                            </CardSubtitle>
                            <CardText>
                              Some quick example text to build on the
                              card title and make up the bulk of the
                              card‘s content.
                            </CardText>
                            <Button>Button</Button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              )}
            </div>
          )}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <ModalHeader toggle={this.toggle}>
              Agent toevoegen
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="voornaam">Voornaam</Label>
                    <Input
                      defaultValue={this.state.voornaam}
                      required
                      onInput={(e) => this.handleInput(e)}
                      id="voornaam"
                      name="voornaam"
                      placeholder="Voornaam"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="achternaam">Achternaam</Label>
                    <Input
                      defaultValue={this.state.achternaam}
                      onInput={(e) => this.handleInput(e)}
                      id="achternaam"
                      name="achternaam"
                      placeholder="Achternaam"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="email">E-mailadres</Label>
                <Input
                  defaultValue={this.state.email}
                  required
                  onInput={(e) => this.handleInput(e)}
                  id="email"
                  name="email"
                  placeholder="E-mailadres"
                  type="email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="wachtwoord">Wachtwoord</Label>
                <Input
                  defaultValue={this.state.wachtwoord}
                  required
                  onInput={(e) => this.handleInput(e)}
                  id="wachtwoord"
                  name="wachtwoord"
                  placeholder="Wachtwoord"
                  type="text"
                />
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="role">Rol</Label>
                <Input
                  id="role"
                  name="role"
                  onInput={(e) => this.handleInput(e)}
                  type="select"
                >
                  <option>-----</option>
                  <option value="admin">Admin</option>
                  <option value="bedrijf">Bedrijf</option>
                  <option value="agent">Agent</option>
                  <option value="monteur">Monteur</option>
                  <option value="planner">Planner</option>
                </Input>
              </FormGroup>

              {this.state.role === 'agent' && (
                <FormGroup className="form-group">
                  <Label for="owner">Agent werkt voor bedrijf?</Label>
                  <Input
                    defaultValue={this.state.owner}
                    id="owner"
                    name="owner"
                    onInput={(e) => this.handleInput(e)}
                    type="select"
                  >
                    <option>-----</option>
                    {this.state.companies.map((a, i) => {
                      return (
                        <option key={i} value={a.id}>
                          {a.bedrijfsnaam}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}
              {this.state.role === 'bedrijf' && (
                <>
                  <FormGroup className="form-group">
                    <Label for="bedrijfsnaam">Bedrijfsnaam</Label>
                    <Input
                      id="bedrijfsnaam"
                      name="bedrijfsnaam"
                      onInput={(e) => this.handleInput(e)}
                      type="text"
                    />
                  </FormGroup>

                  <FormGroup className="form-group">
                    <Label for="commission">Commissie model</Label>
                    <Input
                      id="commission"
                      name="commission"
                      onInput={(e) => this.handleInput(e)}
                      type="select"
                    >
                      <option>-----</option>
                      <option value="pricePerPanel">
                        Prijs per paneel
                      </option>
                      <option value="percentage">Percentage</option>
                    </Input>
                  </FormGroup>

                  {this.state.commission === 'pricePerPanel' && (
                    <FormGroup className="form-group">
                      <Label for="pricePanel">Prijs per paneel</Label>
                      <Input
                        id="pricePanel"
                        name="pricePanel"
                        onInput={(e) => this.handleInput(e)}
                        type="number"
                      />
                    </FormGroup>
                  )}
                  {this.state.commission === 'percentage' && (
                    <FormGroup className="form-group">
                      <Label for="percentage">
                        Te verhogen percentage
                      </Label>
                      <Input
                        id="percentage"
                        name="percentage"
                        onInput={(e) => this.handleInput(e)}
                        type="number"
                      />
                    </FormGroup>
                  )}

                  <FormGroup className="form-group">
                    <Label for="pricePanel">Prijs per paneel</Label>
                    <Input
                      id="pricePanel"
                      name="pricePanel"
                      onInput={(e) => this.handleInput(e)}
                      type="number"
                    />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Annuleren
              </Button>{' '}
              <Button type="submit" color="primary">
                Opslaan
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* <Modal
          isModalOpen={this.state.isModalOpen}
          setModalOpen={this.setModalOpen}
        /> */}
      </div>
    );
  }
}

export default withRouter(Users);
