import { auth, firestore } from './fb';
import _ from 'lodash';
import moment from 'moment';
import { saveLog } from './firebase2';
import axios from 'axios';

const titleCase = (string) => {
  if (string.length > 3)
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
};
const saveKlant = async (id, data) => {
  let error = false;
  let client = await firestore
    .collection('klantenSolvue')
    .doc(id)
    .update(data)
    .catch((error) => {
      error = true;
      console.log('Error getting document:', error);
    });
  // alert("Klant is overgenomen.");
  // window.location.reload(true);
  // return client;
  if (!error) {
    if (data.offertes.length > 0) {
      updateOffertes(data.offertes);
    }
  }
};
const updateOffertes = async (offertes) => {
  for (let index = 0; index < offertes.length; index++) {
    const element = offertes[index];

    await firestore
      .collection('offertesSolvue')
      .doc(element.id)
      .update(element)
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }
  alert('Klant en offertes zijn overgenomen');
  window.location.reload(true);
};
const loadKlant = async (id) => {
  let client = await firestore
    .collection('klantenSolvue')
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        return data;
      } else {
        console.log('No such document!');
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });

  let offertes = await getClientOffertes(client.id);

  client.offertes = offertes;

  return client;
};

const saveOfferte = async (data, props = false) => {
  // console.log(data, "save offerte");
  let docRef = firestore.collection('offertesSolvue').doc();
  let clientRef = firestore
    .collection('klantenSolvue')
    .doc(data.client_id);
  data.id = docRef.id;
  return await docRef.set(data).then(async (doc) => {
    let emails = [];
    if (data.agent) {
      if (data.agent.email) emails.push(data.agent.email);
    }
    if (props) {
      props.push(`/klanten/${data.client_id}`);
    }
    return { error: false };
  });
};

const saveOfferteSolvue2 = async (data, props = false) => {
  // console.log(data, "save offerte");
  let id = data.id;
  let docRef = firestore.collection('offertesSolvue').doc();
  if (id) {
    docRef = firestore.collection('offertesSolvue').doc(id);
  }
  let clientRef = firestore
    .collection('klantenSolvue')
    .doc(data.client_id);
  data.id = docRef.id;
  return await docRef.set(data).then(async (doc) => {
    return { success: true };
  });
};

const getClientOffertes = async (id) => {
  let array = [];

  await firestore
    .collection('offertesSolvue')
    .where('client_id', '==', id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        array.push(data);
      });
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
    });
  return array;
};

const saveClient = async (data) => {
  let docRef = firestore.collection('klantenSolvue').doc(data.id);
  let offerteRef = firestore.collection('offertesSolvue');

  console.log(data);
  if (!_.isEmpty(data.offertes)) {
    await data.offertes.map((a) => {
      console.log(a);
      // offerteRef.doc(a.id).update(a);
    });
  }

  return await docRef.update(data).then((doc) => {
    saveLog(data, 'Klant/Lead aangepast', false, false);
    return { message: 'success', error: false };
  });
};

const sendOfferteClient = async (data, user) => {
  let idToken = await auth.currentUser.getIdToken(true);

  const msg = {
    to: [data.email], // Change to your recipient
    replyto: 'info@solvue.nl',
    from: 'SolVue <info@solvue.nl>', // Change to your verified sender
    subject: `Uw Persoonlijke Offerte voor Duurzame Energieoplossingen van Solvue - Offerte nr. ${data.offerte}`,
    html: `<img src="https://solvue.nl/wp-content/uploads/2023/09/logo-1.png" style="width:150px;display:block" />
    <h3>Uw persoonlijke pad naar een duurzame toekomst: Offerte nr. ${
      data.offerte
    }</h3>
    <p>Geachte ${data.aanhef} ${data.achternaam.trim()},</p>
    <p>Dank u wel voor uw interesse in de duurzame energieoplossingen van Solvue. U staat op het punt een significante stap te zetten naar een groenere toekomst en substantiële besparingen.</p>
    <p>Wat u kunt verwachten in dit voorstel:</p>
    <ul>
      <li>${
        data.panel.name
      } met een indrukwekkende garantie van 25 jaar.</li>
      <li>${
        data.inverter.name
      } eveneens met een vertrouwde garantie van 25 jaar.</li>
      ${
        data.inverter.inverterType === 'micro'
          ? '<li>Uw zonnesysteem wordt parallel geschakeld met behulp van geavanceerde micro-omvormers, wat zorgt voor een efficiënte energieopwekking.</li>'
          : ''
      }
      <li>Esdec Clickfit EVO onderstel montagesysteem, bekend om zijn betrouwbare installatie, met een garantie van 20 jaar.</li>
    </ul>
    <p>Na uw goedkeuring kunnen we uw nieuwe zonnesysteem binnen 3 weken installeren. U ontvangt van ons een online checklist die u dient in te vullen. Deze checklist helpt ons om alle benodigde informatie te verzamelen, zodat een fysieke schouw in veel gevallen overbodig wordt. Mochten we echter constateren dat de verstrekte informatie niet voldoende is, dan plannen we alsnog een gedetailleerde schouw in om ervoor te zorgen dat alles soepel verloopt op de dag van de installatie.</p>
    <p>Uw persoonlijke dashboard, toegankelijk via de onderstaande knop, biedt u een op maat gemaakt voorstel.</p>
    <a style="background: #6AB5E7; padding: 10px 20px; margin: 0; display: inline-block; color: #fff; font-weight: bold; text-decoration: unset;" href="https://app.solvue.nl/voorstel/${
      data.id
    }">Bekijk uw offerte</a>
    <hr>
    <footer>
      <p>Met vriendelijke groet,</p>
      <p>Het Solvue Team</p>
      <p style="margin:0"><strong>Solvue</strong></p>
      <p style="margin:0">Adres:</p>
      <p style="margin:0">Krammer 8</p>
      <p style="margin:0">3232 HE Brielle</p>
      <p style="margin:0">Email: <a href="mailto:info@solvue.nl">info@solvue.nl</a></p>
      <p style="margin:0">Website: <a href="https://solvue.nl">www.solvue.nl</a></p>
    </footer>`,
    text: `Uw persoonlijke pad naar een duurzame toekomst: Offerte nr. ${
      data.offerte
    }

Geachte ${data.aanhef} ${data.achternaam.trim()},

Dank u wel voor uw interesse in de duurzame energieoplossingen van Solvue. U staat op het punt een significante stap te zetten naar een groenere toekomst en substantiële besparingen.

Wat u kunt verwachten in dit voorstel:
- ${data.panel.name} met een indrukwekkende garantie van 25 jaar.
- ${
      data.inverter.name
    } eveneens met een vertrouwde garantie van 25 jaar.
${
  data.inverter.inverterType === 'micro'
    ? '- Uw zonnesysteem wordt parallel geschakeld met behulp van geavanceerde micro-omvormers, wat zorgt voor een efficiënte energieopwekking.'
    : ''
}
- Esdec Clickfit EVO onderstel montagesysteem, bekend om zijn betrouwbare installatie, met een garantie van 20 jaar.

Na uw goedkeuring kunnen we uw nieuwe zonnesysteem binnen 3 weken installeren. U ontvangt van ons een online checklist die u dient in te vullen. Deze checklist helpt ons om alle benodigde informatie te verzamelen, zodat een fysieke schouw in veel gevallen overbodig wordt. Mochten we echter constateren dat de verstrekte informatie niet voldoende is, dan plannen we alsnog een gedetailleerde schouw in om ervoor te zorgen dat alles soepel verloopt op de dag van de installatie.

Uw persoonlijke dashboard, toegankelijk via de link: [Bekijk uw offerte](https://app.solvue.nl/voorstel/${
      data.id
    })

Met vriendelijke groet,
Het Solvue Team
Adres:
  Krammer 8
  3232 HE Brielle
Email: info@solvue.nl
Website: www.solvue.nl
`,
  };

  // return msg;
  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/sendofferte-solvue`,
      { data: msg },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      // console.log(data);
      // saveLog(
      //   data,
      //   "Offerte verstuurd naar klant",
      //   false,
      //   false,
      //   data.id,
      //   user
      // );
      updateNewStatus(data.client_id, 'OFFERTE_VERZONDEN', user);

      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const GetData = async (
  user,
  range,
  archived = false,
  month = false
) => {
  let start = moment();
  let end = moment();
  let queryRef = firestore.collection('offertesSolvue');
  // console.log(range, "range");
  if (month) {
    start = moment()
      .month(parseInt(range, 10) - 1)
      .startOf('month')
      .format();
    end = moment()
      .month(parseInt(range, 10) - 1)
      .endOf('month')
      .format();

    queryRef = queryRef
      .where('sendAt', '>=', start)
      .where('sendAt', '<=', end);
  }
  if (!month) {
    start = start.subtract(range, 'd').format();
    end = end.format();

    if (range !== 1 && range !== 0) {
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    } else if (range === 1) {
      start = moment().subtract(range, 'd').startOf('day').format();
      end = moment().subtract(range, 'd').endOf('day').format();
      console.log(start, 'Start of day');
      console.log(end, 'End of day');
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    } else if (range === 0) {
      start = moment().startOf('day').format();
      end = moment().endOf('day').format();
      console.log(start, 'Start of day');
      console.log(end, 'End of day');
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    }
  }

  switch (user.role) {
    case 'admin':
      let newData = [];

      if (user.subRole === 'agent') {
        queryRef = queryRef.where('agent.id', '==', user.id);
      }
      const querySnapshot = await queryRef.get();

      querySnapshot.docs.map((doc) => {
        // console.log(doc.data());
        if (!archived && !doc.data().archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        } else if (archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      let adminQueryRef = await firestore
        .collection('offertesSolvue')
        .where('updatedAt', '>=', start)
        .where('updatedAt', '<=', end);

      if (user.subRole === 'agent') {
        adminQueryRef = adminQueryRef.where(
          'agent.id',
          '==',
          user.id
        );
      }
      const AdminSignedSnapshot = await adminQueryRef.get();
      AdminSignedSnapshot.docs.map((doc) => {
        if (doc.data().signedAt) {
          let res = moment(doc.data().signedAt, 'LLL').isBetween(
            start,
            end
          );

          // console.log(start, "start date");
          // console.log(end, "end date");
          // console.log(res, start, end);
          if (res) {
            if (!archived && !doc.data().archived) {
              let da = doc.data();
              if (da.signedAt) {
                const found = newData.some((el) => el.id === doc.id);
                if (!found)
                  newData.push({
                    id: doc.id,
                    status: 'signed',
                    old: false,
                    ...doc.data(),
                  });
              }
              // if (doc.data().agent.id === user.id) {
              // }
            }
          }
          //
        }
      });

      newData = _.sortBy(newData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      // console.log(newData);
      return newData;

    case 'bedrijf':
      const companySnapshot = await firestore
        .collection('offertesSolvue')
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end)
        .where('agent.owner', '==', user.id)
        .get();
      let companyData = [];
      companySnapshot.docs.map((doc) => {
        let data = doc.data();
        // console.log(data.agent.voornaam);

        if (!archived && !doc.data().archived) {
          // let data = doc.data();
          // console.log(data.agent);
          // console.log(data.userID);
          // if (doc.data().agent.owner === user.id)
          companyData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      companyData = _.sortBy(companyData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      return companyData;

    case 'agent':
      console.log('get clients agents');
      let agentData = [];
      const agentSnapshot = await firestore
        .collection('offertesSolvue')
        .where('updatedAt', '>=', start)
        .where('updatedAt', '<=', end)
        .where('agent.id', '==', user.id)
        .get();

      agentSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      const signedSnapshot = await firestore
        .collection('offertesSolvue')
        .where('signedAt', '>=', start)
        .where('signedAt', '<=', end)
        .where('agent.id', '==', user.id)
        .get();
      signedSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      agentData = _.sortBy(agentData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      console.log(agentData, 'agent data');
      return agentData;
    default:
      return false;
  }
};

const getStatusData = async (
  user,
  range,
  archived = false,
  month = false
) => {
  let start = moment();
  let end = moment();
  let queryRef = firestore.collection('offertesSolvue');
  // console.log(range, "range");
  if (month) {
    start = moment()
      .month(parseInt(range, 10) - 1)
      .startOf('month')
      .format();
    end = moment()
      .month(parseInt(range, 10) - 1)
      .endOf('month')
      .format();

    queryRef = queryRef
      .where('sendAt', '>=', start)
      .where('sendAt', '<=', end);
  }
  if (!month) {
    start = start.subtract(range, 'd').format();
    end = end.format();

    if (range !== 1 && range !== 0) {
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    } else if (range === 1) {
      start = moment().subtract(range, 'd').startOf('day').format();
      end = moment().subtract(range, 'd').endOf('day').format();
      console.log(start, 'Start of day');
      console.log(end, 'End of day');
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    } else if (range === 0) {
      start = moment().startOf('day').format();
      end = moment().endOf('day').format();
      console.log(start, 'Start of day');
      console.log(end, 'End of day');
      queryRef = queryRef
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end);
    }
  }

  switch (user.role) {
    case 'admin':
      let newData = [];

      if (user.subRole === 'agent') {
        queryRef = queryRef.where('agent.id', '==', user.id);
      }
      const querySnapshot = await queryRef.get();

      querySnapshot.docs.map((doc) => {
        // console.log(doc.data());
        if (!archived && !doc.data().archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        } else if (archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      let adminQueryRef = await firestore
        .collection('offertesSolvue')
        .where('updatedAt', '>=', start)
        .where('updatedAt', '<=', end);

      if (user.subRole === 'agent') {
        adminQueryRef = adminQueryRef.where(
          'agent.id',
          '==',
          user.id
        );
      }
      const AdminSignedSnapshot = await adminQueryRef.get();
      AdminSignedSnapshot.docs.map((doc) => {
        if (doc.data().signedAt) {
          let res = moment(doc.data().signedAt, 'LLL').isBetween(
            start,
            end
          );

          if (res) {
            if (!archived && !doc.data().archived) {
              let da = doc.data();
              if (da.signedAt) {
                const found = newData.some((el) => el.id === doc.id);
                if (!found)
                  newData.push({
                    id: doc.id,
                    status: 'signed',
                    old: false,
                    ...doc.data(),
                  });
              }
            }
          }
        }
      });

      newData = _.sortBy(newData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      return newData;

    case 'bedrijf':
      const companySnapshot = await firestore
        .collection('offertesSolvue')
        .where('sendAt', '>=', start)
        .where('sendAt', '<=', end)
        .where('agent.owner', '==', user.id)
        .get();
      let companyData = [];
      companySnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          companyData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      companyData = _.sortBy(companyData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      return companyData;

    case 'agent':
      // console.log('get clients agents');
      let agentData = [];
      const agentSnapshot = await firestore
        .collection('offertesSolvue')
        .where('updatedAt', '>=', start)
        .where('updatedAt', '<=', end)
        .where('agent.id', '==', user.id)
        .get();

      agentSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      const signedSnapshot = await firestore
        .collection('offertesSolvue')
        .where('signedAt', '>=', start)
        .where('signedAt', '<=', end)
        .where('agent.id', '==', user.id)
        .get();
      signedSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      agentData = _.sortBy(agentData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      // console.log(agentData, 'agent data');
      return agentData;
    default:
      return false;
  }
};

const queryOffertes = async (e, user) => {
  console.log(user);
  let newData = {
    achternaam: [],
    straat: [],
  };

  let offertes = firestore.collection('offertesSolvue');

  if (user.role === 'agent') {
    offertes = offertes.where('agent.id', '==', user.id);
  }

  if (user.role === 'bedrijf') {
    offertes = offertes.where('agent.owner', '==', user.id);
  }

  const lowerCase = titleCase(e);
  // const lowerCase = titleCase(e);

  const isName = await offertes
    .where('achternaam', '>=', e)
    .where('achternaam', '<=', e + '~')
    .get();
  isName.docs.map((doc) => {
    newData.achternaam.push(doc.data());
  });
  const isName2 = await offertes
    .where('achternaam', '>=', lowerCase)
    .where('achternaam', '<=', lowerCase + '~')
    .get();
  isName2.docs.map((doc) => {
    newData.achternaam.push(doc.data());
  });
  const isStreet = await offertes
    .where('straat_nummer', '>=', e)
    .where('straat_nummer', '<=', e + '~')
    .get();
  isStreet.docs.map((doc) => {
    newData.straat.push(doc.data());
  });
  const isStreet2 = await offertes
    .where('straat_nummer', '>=', lowerCase)
    .where('straat_nummer', '<=', lowerCase + '~')
    .get();
  isStreet2.docs.map((doc) => {
    newData.straat.push(doc.data());
  });

  return newData;
};
const loadClientsAgent = async (id) => {
  const docRef = firestore.collection('klantenSolvue');

  let array = { error: false, data: [], message: '', total: 0 };
  await docRef
    .where('userID.id', '==', id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (data.updated) data.updated = data.updated.toDate();
        // doc.data() is never undefined for query doc snapshots
        array.data.push(data);
        array.total++;
      });
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
    });

  if (array.total === 0) {
    array.error = true;
    array.message = 'Geen klanten gevonden.';
  }
  console.log(array);
  return array;
};

const loadCompanyAgent = async (user) => {
  const docRef = firestore.collection('klantenSolvue');

  let array = { error: false, data: [], message: '', total: 0 };
  if (user) {
    await docRef
      // .where("companyID.id", "==", user)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          // console.log(user.id);
          data.id = doc.id;
          // if (typeof data.companyID === "undefined") console.log(data);

          if (data.userID.id === user.id) {
            data.user = doc.id;

            data.createdAt = data.createdAt.toDate();
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          }

          if (data.companyID && data.companyID.id === user.id) {
            data.user = doc.id;

            if (
              data.createdAt &&
              typeof data.createdAt === 'object'
            ) {
              data.createdAt = data.createdAt.toDate();
            }
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          } else if (
            data.userID &&
            data.userID.company &&
            data.userID.company.id === user.id
          ) {
            data.user = doc.id;

            if (
              data.createdAt &&
              typeof data.createdAt === 'object'
            ) {
              data.createdAt = data.createdAt.toDate();
            }
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          }
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    if (array.total === 0) {
      array.error = true;
      array.message = 'Geen klanten gevonden.';
    }
  }

  console.log(array);

  return array;
};

const loadAllClients = async () => {
  const docRef = firestore.collection('klantenSolvue');
  let array = { error: false, data: [], message: '', total: 0 };
  await docRef
    // .limit(1)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (typeof data.updatedAt === 'object')
          data.updatedAt = data.updatedAt.toDate();
        // doc.data() is never undefined for query doc snapshots
        array.data.push(data);
        array.total++;
      });
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
    });
  if (array.total === 0) {
    array.error = true;
    array.message = 'Geen klanten gevonden.';
  }
  // console.log(array.data);

  return array;
};
const getOfferte = async (id) => {
  let offerte = await firestore
    .collection('offertesSolvue')
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        console.log('No such document!');
        return false;
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });
  // console.log(offerte);
  return offerte;
};

const calculatePrices2 = async (
  panelen,
  kwh,
  tarief,
  wp,
  helling,
  user,
  client,
  history,
  personalMessage,
  images,
  setTheState,
  setDiscount,
  companyData,
  pricePanel,
  commissionType,
  percentage
) => {
  console.log(companyData);
  if (!_.isEmpty(companyData.priceChange)) {
    let omrekenFactor = companyData.priceChange[panelen] / 100;

    if (panelen > 30) {
      //
      if (user && user.role !== 'admin') {
        alert(
          'Neem contact op met je leidinggevende. Boven de 30 panelen moet een handmatige berekening gemaakt worden.'
        );
      } else {
        pricePanel = pricePanel * (companyData.priceChange[30] / 100);

        history.goBack();
      }
    } else {
      pricePanel = pricePanel * omrekenFactor;
    }
  }

  if (!_.isEmpty(companyData.config)) {
    if (companyData.config.multiplier) {
      pricePanel = pricePanel * companyData.config.multiplier;
    }
  }
  // console.log(pricePanel, "before change inverter");
  // console.log(client);
  if (client.inverter && client.inverter.inverterType === 'string') {
    pricePanel = pricePanel * (client.inverter.price / 100);
  }
  if (client.inverter && client.inverter.inverterType === 'micro') {
    pricePanel = pricePanel * (client.inverter.price / 100);
  }

  console.log(pricePanel, 'prijs per paneel na convert');
  // if (client.inverter && client.inverter.id === "HZ20118") {
  //   pricePanel = pricePanel + 110;
  // }
  let priceEx = panelen * pricePanel;

  if (commissionType === 'percentage' && percentage) {
    let bedrag = priceEx * (percentage / 100);

    priceEx = priceEx + bedrag;
    client.packPrice = _.cloneDeep(priceEx);
  }
  let extraExPrice = 0;
  let discountPrice = priceEx;
  client.extraProducts.map(
    (a) => (extraExPrice = extraExPrice + a.price)
  );

  if (extraExPrice > 0) {
    priceEx = priceEx + extraExPrice;
  }
  if (client.addonPrice > 0) {
    priceEx = priceEx + client.addonPrice;
    client.packPrice = client.packPrice + client.addonPrice;
  }
  if (client.addonPrice < 0) {
    priceEx = priceEx + client.addonPrice;

    client.packPrice = client.packPrice + client.addonPrice;
  }
  client.discountPrice =
    ((client.packPrice - discountPrice) / client.packPrice) * 100;
  const opbrengst = kwh * tarief;

  const btw = priceEx * 0.21;

  const priceInc = priceEx + btw;

  const terugVerdien = priceEx / opbrengst;

  let pricing = {
    exbtw: priceEx,
    btw_bedrag: btw,
    inclbtw: priceInc,
  };

  let returnOnInverst = {
    terugVerdien: terugVerdien,
    opbrengst: opbrengst,
  };
  let object = {
    panelen: panelen,
    kwh: kwh,
    exbtw: priceEx,
    btw_bedrag: btw,
    inclbtw: priceInc,
    wp: wp,
  };
  client.exbtw = priceEx;
  client.btw_bedrag = btw;
  client.inclbtw = priceInc;
  client.terugVerdien = terugVerdien;
  client.euro = opbrengst;
  client.opbrengst = opbrengst;
  client.kwh = kwh;
  client.wp = wp;
  client.tarief = tarief;
  client.panelen = panelen;
  client.helling = helling;
  client.personalMessage = personalMessage;
  client.images = images;
  if (client.korting.isKorting && client.discount) {
    client.korting = await setDiscount(client.discount);
    client.terugVerdien = client.korting.inclbtw / opbrengst;
    returnOnInverst.terugVerdien = client.korting.inclbtw / opbrengst;
  }
  console.log(client, 'calculatePrices2');
  setTheState({
    pricing,
    returnOnInverst,
    client,
    showManual: false,
  });
};
const editOfferte = async (data, sendEmail) => {
  // console.log(data);
  let docRef = firestore.collection('offertesSolvue').doc(data.id);
  let clientRef = firestore
    .collection('klantenSolvue')
    .doc(data.client_id);
  data.statusNew = 'AANGEMAAKT';
  return await docRef.update(data).then(async (doc) => {
    let emails = ['backoffice@hellozon.nl'];
    if (data.agent) {
      if (data.agent.email) emails.push(data.agent.email);
      if (data.agent.company && data.agent.company.email)
        emails.push(data.agent.company.email);
    }
    return await clientRef
      .update({
        status: 'ready',
      })
      .then((r) => {
        return { error: false };
      })
      .catch((err) => console.log(err));
  });
};

const sendSignedOfferte = async (data, offerte, updateStatus) => {
  // let idToken = await auth.currentUser.getIdToken(true);

  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/mail-signed-solvue`,
      { offerte, data },
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json
          authtoken: '91827678913876183',
        },
      }
    )
    .then((res) => {
      console.log('axios post done successful');
      if (res.data.success) {
        // console.log("successvol signed, updating status");
        // return res.data; // test

        updateNewStatus(offerte.client_id, 'OFFERTE_GETEKEND');
      }
      return res.data;
    });

  // return { success: true };
};

const sendLegplanMaken = async (data) => {
  let idToken = await auth.currentUser.getIdToken(true);

  const msg = {
    to: ['werkvoorbereiding@solvue.nl'], // Change to your recipient
    replyto: data.agent.email,
    from: `SolVue | SolVue <info@solvue.nl>`, // Change to your verified sender
    subject: `Aanvraag legplan maken ${data.agent.bedrijfsnaam} - Offerte nr. ${data.offerte}`,
    html: `<img src="https://solvue.nl/wp-content/uploads/2023/09/logo-1.png" style="width:150px;display:block" />
    <h3>Adres:</h3>
    <p>${data.straat_nummer}</p>
    <p>${data.postcode_plaats}</p>
    <hr>
    <h3>Gegevens</h3>
    <p>Aantal panelen: ${data.panelen}</p>
    <p>Type paneel: ${data.panel.name}</p>
    <p>Soort omvormer: ${data.inverter.name}</p>
    <hr>
    <p>Reageer op deze email wanneer het legplan afgerond is.</p>

    <footer>
    </footer>`,
  };
  console.log('try sending email legplan maken');
  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/legplan-laten-maken`,
      { msg },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      console.log('axios post done successful');

      // if (res.data.success) {
      //   console.log(res.data);

      //   // console.log("successvol signed, updating status");
      //   // return res.data; // test
      // }
      console.log(res.data, 'Sending data');

      return res.data;
    });

  // return { success: true };
};
const updateNewStatus = async (id, status, user = false) => {
  await firestore
    .collection('klantenSolvue')
    .doc(id)
    .update({
      statusNew: status,
      updatedAt: moment().format(),
      updatedBy: user,
    })
    .then(() => console.log('updated'))
    .catch((err) => console.log(err));
};
const updateNewOfferteStatus = async (id, status, user) => {
  await firestore
    .collection('offertesSolvue')
    .doc(id)
    .update({
      statusNew: status,
      updatedAt: moment().format(),
      updatedBy: user,
    })
    .then(() => console.log('updated'))
    .catch((err) => console.log(err));
};
const saveSignedOfferte = async (offerte) => {
  // return { error: false }; // test

  let docRef = firestore.collection('offertesSolvue').doc(offerte.id);
  let clientRef = firestore
    .collection('klantenSolvue')
    .doc(offerte.client_id);
  return await docRef.update(offerte).then(async () => {
    return await clientRef
      .update({
        status: 'signed',
        statusNew: 'OFFERTE_GETEKEND',
      })
      .then(async (r) => {
        return await docRef
          .update({
            status: 'signed',
            statusNew: 'OFFERTE_GETEKEND',
          })
          .then(() => {
            return { error: false };
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
};

export {
  getStatusData,
  queryOffertes,
  saveClient,
  saveKlant,
  loadKlant,
  loadAllClients,
  loadCompanyAgent,
  loadClientsAgent,
  saveOfferte,
  sendOfferteClient,
  getOfferte,
  editOfferte,
  calculatePrices2,
  sendSignedOfferte,
  saveSignedOfferte,
  sendLegplanMaken,
  saveOfferteSolvue2,
};
