import React from 'react';
import './App.css';
import {
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Login from './pages/Login';
import { AuthProvider } from './Authentication';
import PrivateRoute from './PrivateRoute';
import _ from 'lodash';
import Firebase, { getUser, getProducts } from './helpers/firebase';
// import Dashboard from "./pages/Dashboard";
import NavBarComponent from './components/NavBar';
import UserList from './pages/users';
import EcoLexOffertes from './pages/EcoLexOffertes';
import AanvraagEcolex from './pages/EcoLex/Aanvraag';

// import { useLocation } from "react-router";
import { Toaster } from 'react-hot-toast';

import { addUserState } from './redux/slices/userSlice';
import { getProductsSlice } from './redux/slices/productSlice';
import { useDispatch } from 'react-redux';
import SingleKlantEcolex from './pages/EcoLex/SingleKlantEcolex';
import EcoLexKlanten from './pages/EcoLexKlanten';
import OfferteEcolex from './pages/OfferteEcolex';
import EcolexOfferteEdit from './pages/EcolexOfferteEdit';
import EcolexClientView from './pages/EcolexClientView';
import EcolexKlantView from './pages/EcolexKlantView';

function App(props) {
  const [isAuth, setAuth] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState(false);

  const dispatch = useDispatch();
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };
  let string = usePathname();
  let history = useHistory();
  React.useEffect(() => {
    // console.log(string);
    if (
      string.includes('voorstel') ||
      string.includes('klant/offerte')
    ) {
      console.log('skip auth');
    } else {
      let checking = false;

      // console.log("checking auth");
      Firebase.auth().onAuthStateChanged(async (user) => {
        // setLoading(true);

        if (user) {
          // console.log(user);
          setAuth(true);

          setUser(user);
          // console.log(user);
          if (isAuth) {
            let userProfile = await getUser(user.uid, history).then(
              (res) => {
                // console.log(res);
                return res;
              }
            );
            // console.log(userProfile);
            dispatch(addUserState(userProfile));

            await getProducts().then((res) => {
              let groupedProducts = _.chain(res)
                // Group the elements of Array based on `color` property
                .groupBy('category')
                // `key` is group's name (color), `value` is the array of objects
                .map((value, key) => ({
                  category: key,
                  products: value,
                }))
                .value();
              // setLoading(false);

              return dispatch(getProductsSlice(groupedProducts));
            });
          }
        } else {
          setAuth(false);
        }
      });
      return true;
    }
  });

  return (
    <div className={`App`}>
      <div>
        <Toaster />
      </div>
      {loading && <div className="loader">Loading</div>}

      {user && <NavBarComponent user={user} />}

      <AuthProvider>
        <Switch>
          {/* <PrivateRoute
            path="/"
            exact
            component={ChooseProposition}
          /> */}
          {/* <PrivateRoute
            path="/hellozon/"
            exact
            component={OfferteAanvragen}
          /> */}
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/" exact component={EcoLexOffertes} />
          <PrivateRoute
            path="/new"
            exact
            component={AanvraagEcolex}
          />
          <PrivateRoute
            path="/klanten/"
            exact
            component={EcoLexKlanten}
          />
          <PrivateRoute
            path="/klanten/:id"
            exact
            component={SingleKlantEcolex}
          />
          <PrivateRoute
            path="/offerte/nieuw/:id"
            exact
            component={OfferteEcolex}
          />
          <PrivateRoute
            path="/offerte/edit/:id"
            exact
            component={EcolexOfferteEdit}
          />
          <PrivateRoute
            path="/klanten/:id/offerte/:offerteID"
            exact
            component={EcolexClientView}
          />
          <Route
            path="/voorstel/:id"
            exact
            component={EcolexKlantView}
          />
          {/* End Ecolex */}

          {/* Start HelloZon
          <PrivateRoute
            path="/hellozon/klanten/"
            exact
            component={Klanten}
          />
          <PrivateRoute
            path="/hellozon/klanten/:id"
            exact
            component={SingleKlant}
          />
          <PrivateRoute
            path="/hellozon/relatie/:id"
            exact
            component={RelatieView}
          />
          <PrivateRoute
            path="/hellozon/klanten/:id/offerte/:offerteID"
            exact
            component={ClientView}
          />
          <PrivateRoute
            path="/hellozon/offerte/nieuw/:id"
            exact
            component={OfferteNieuw2}
          />

          <PrivateRoute path="/" exact component={Relaties} />
          <PrivateRoute
            path="/hellozon/offerte/edit/:id"
            exact
            component={OfferteEdit}
          />
          <PrivateRoute
            path="/hellozon/offerte/new/:id"
            exact
            component={OfferteNieuw}
          />
          <PrivateRoute
            path="/hellozon/offerte/:id"
            exact
            component={View}
          />
          <PrivateRoute
            path="/hellozon/new"
            exact
            component={Aanvraag}
          /> */}
          <PrivateRoute path="/users/" exact component={UserList} />
          {/* <PrivateRoute
            path="/hellozon/relaties/"
            exact
            component={Relaties}
          />
          <Route
            path="/hellozon/klant/offerte/:id"
            exact
            component={KlantView}
          />
          <Route
            path="/hellozon/voorstel/:id"
            exact
            component={KlantViewV2}
          />
          <PrivateRoute
            path="/hellozon/aanvragen"
            exact
            component={Aanvragen}
          />
          <PrivateRoute
            path="/hellozon/aanvragen/:id/:tab?"
            exact
            component={SingleAanvraag}
          />
          <PrivateRoute
            path="/hellozon/templates/:template/:id"
            exact
            component={TemplateHolder}
          />
          <PrivateRoute
            path="/hellozon/factuur/create/:id/:invoiceNr?"
            exact
            component={FactuurHolder}
          />
          <PrivateRoute
            path="/hellozon/factuur/view/:id"
            exact
            component={FactuurHolder}
          /> */}
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
