const status = {
  AANGEMAAKT: 'Aangemaakt',
  LEGPLAN_MAKEN: 'Ontwikkeling Legplan',
  OFFERTE_VERZONDEN: 'Offerte verzonden',
  OFFERTE_GETEKEND: 'Offerte getekend',
  WELKOMSTMAIL_AANBETALING_VERSTUREN: 'Welkomstmail versturen', // automatisch trustpilot
  WACHT_OP_QC_SALES: 'Wacht op QC sales',
  SCHOUW_INPLANNEN: 'Schouw inplannen',
  INSTALLATIE_INPLANNEN: 'Installatie inplannen',
  WACHT_OP_INSTALLATIE: 'Wacht op installatie',
  EINDFACTUUR_BETAALD: 'Eindfactuur betaald',
  WACHT_OP_QC: 'Wacht op QC',
  VESTUREN_EIND_MAIL: 'Versturen eindmail', // automatisch trustpilot op basis van qc call
  KLAAR: 'Klaar',
};

export default status;
