import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from '@react-pdf/renderer';
import React from 'react';
import Logo from '../../images/logo.png';
import ErvarenMonteur from './sources/ervaren-monteurs.jpg';
import {
  convertTerugVerdien,
  formatEuro,
} from '../../helpers/firebase';
import moment from 'moment';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Open Sans',
    padding: '0 40px 0 0',
  },
  page2: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Open Sans',
    padding: '0 40px',
  },
  images: {
    logoFixed: {
      position: 'absolute',
      top: 20,
      right: 20,
      height: '30px',
    },
    signature: {
      width: 150,
      height: 'auto',
    },
    banner: {
      top: 0,
      width: 100,
      height: 100,
      marginLeft: 0,
    },
    vooraanzicht: {
      position: 'absolute',
      width: 220,
      height: 165,
      bottom: 50,
      right: 30,
      padding: 5,
      border: '5px solid orange',
    },
  },
  section: {
    page7: {
      topLeft: {
        padding: 10,
        color: '#333',
        fontSize: '11px',
      },
      topRight: {
        position: 'absolute',
        color: '#555',
        fontSize: '11px',
        right: '20px',
        top: '10px',
      },
    },
    page1: {
      topLeft: {
        margin: '50px 10px 30px 30px',
        padding: 10,
        color: '#333',
        fontSize: '24px',
        fontWeight: 600,
      },
      topRight: {
        position: 'absolute',
        color: '#999',
        fontSize: '9px',
        right: '40px',
        top: '65px',
      },
      contact: {
        fontSize: '12px',
        margin: '30px 10px 30px 50px',
      },
    },
    page2: {
      logo: {
        width: '80px',
        marginTop: '15px',
      },
      header: {
        margin: '50px 10px 30px 30px',
        padding: 10,
        color: '#333',
        fontSize: '24px',
        fontWeight: 600,
      },
      topRight: {
        position: 'absolute',
        color: '#999',
        fontSize: '9px',
        right: '20px',
        top: '10px',
      },
      welcomeText: {
        display: 'block',
        fontSize: '10px',
        marginBottom: '15px',
      },
      welcomeText2: {
        display: 'block',
        fontSize: '10px',
      },
      inhoud: {
        border: '2px solid #6AB5E7',
        padding: '20px',
        display: 'flex',
        fontSize: 10,
        position: 'absolute',
        right: 20,
        top: 0,
        // alignItems: "center",
      },
    },
    margin: 10,
    padding: 10,
  },
  headerText: {
    fontSize: '21px',
    fontWeight: 700,
  },
  subText: {
    fontSize: '15px',
    fontWeight: 600,
  },
  headerBlock: {
    color: '#888',
    marginTop: 60,
  },
  headerBlockBesparing: {
    color: '#888',
    marginTop: 80,
  },
  fontBold: {
    fontWeight: 700,
  },
  head: {
    fontWeight: 700,
    fontSize: '18px',
  },
  viewer: {
    width: '100%', //the pdf viewer will take up all of the width and height
    height: window.innerHeight * 0.75,
  },
  border: {
    big: {
      borderBottom: '15px solid #d3d3d3',
      display: 'block',
      marginBottom: 40,
    },
    small: {
      borderBottom: '1px solid #d3d3d3',
      marginBottom: '10px',
      marginTop: '20px',
      display: 'block',
    },
    smallAlt: {
      borderBottom: '1px solid #d3d3d3',
      marginBottom: '10px',
      marginTop: '10px',
      display: 'block',
    },
    smallOther: {
      borderBottom: '1px solid #d3d3d3',
      marginBottom: '5px',
      marginTop: '5px',
      display: 'block',
    },
  },
  listItem: {
    display: 'block',
    marginLeft: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  tableRowBorderBottom: {
    margin: 'auto',
    flexDirection: 'row',
    // borderBottom: '1px solid #d3d3d3',
  },
  tableRowLast: {
    margin: 'auto',
    flexDirection: 'row',
    borderBottom: '2px solid #6AB5E7',
  },
  tableRowHead: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#6AB5E7',
    color: '#fff',
    fontWeight: '700',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBig: {
    width: '75%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColSmall: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
  },
  tableCellItalic: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 8,
    color: 'grey',
  },
  tableCellRight: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    justifyContent: 'right',
    textAlign: 'right',
  },
  tableCellKorting: {
    justifyContent: 'right',
    textAlign: 'right',
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#6AB5E7',
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

function Template1(props) {
  console.log(props, 'Template props');
  let showPersonal = false;
  if (
    props.data.personalMessage &&
    props.data.personalMessage.length > 0
  ) {
    showPersonal = true;
  }

  props.data.welcomeText = `Beste ${props.data.aanhef} ${props.data.achternaam},

Fantastisch dat u interesse toont in een duurzamere toekomst met SolVue! Deze offerte is niet zomaar een document; het is uw eerste stap naar enorme besparingen en een groenere levensstijl. Klinkt dat niet als muziek in de oren?

Stelt u zich eens voor: 30 jaar lang lagere energiekosten en een CO2-voetafdruk om trots op te zijn. Dat is wat u krijgt met onze zonnepanelen. En weet u wat het beste is? De energie die u opwekt is 100% duurzaam. Samen maken we Nederland groener, één dak tegelijk.

Bij Solvue snappen we dat verandering spannend kan zijn. Daarom maken we het u zo gemakkelijk mogelijk. Van uitgebreide garanties tot flexibele service-opties, wij staan altijd voor u klaar.

Dus, waar wacht u nog op? Blader snel verder en ontdek wat SolVue voor u kan betekenen. De toekomst is groen en die toekomst begint nu!

`;

  let hideBTW = true;
  if (props.data.zakelijk) hideBTW = false;
  let wp = props.data.wp;

  if (props.data.panel && props.data.panel.wp) {
    wp = props.data.panel.wp * props.data.panelen;
  }
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Image style={styles.images.logoFixed} src={Logo} />
          </View>
          <View style={styles.section.page1.topLeft}>
            {/* <Image style={styles.images.banner} src={SocialLogo} /> */}
            <Text
              style={{
                display: 'block',
                borderBottom: '4px solid #6AB5E7',
                maxWidth: 200,
              }}
            >
              Uw offerte
            </Text>
          </View>

          <View style={styles.section.page1.topRight}>
            <Text style={styles.fontBold}>SolVue</Text>
            <Text>Krammer 8</Text>
            <Text>3232 HE Brielle</Text>
            <Text>info@solvue.nl</Text>
          </View>

          <View style={styles.section.page1.contact}>
            {/* <Text style={styles.head}>ZONNESYSTEEM {wp}Wp</Text> */}
            <Text style={{ marginTop: 0, fontWeight: 'bold' }}>
              {props.data.voornaam
                ? props.data.voornaam
                : props.data.voorletter}{' '}
              {props.data.achternaam}
            </Text>
            <Text style={{ fontWeight: 'bold' }}>
              {props.data.straat_nummer}
            </Text>
            <Text style={{ fontWeight: 'bold', marginBottom: 30 }}>
              {props.data.postcode_plaats}
            </Text>
            {/* <Text style={{ marginTop: '10px' }}>
              {props.data.email}
            </Text>
            <Text style={{ marginBottom: '10px' }}>
              {props.data.telefoonnummer}
            </Text> */}

            <Text>Offerte nr.: {props.data.offerte}</Text>
            <Text>
              Datum: {moment(props.data.today).format('DD MMMM YYYY')}
            </Text>
          </View>
          <View style={styles.section.page1.contact}>
            <Text style={{ marginBottom: 10 }}>
              Geachte {props.data.aanhef} {props.data.achternaam},
            </Text>

            <Text style={{ marginBottom: 10 }}>
              Hartelijk dank voor uw interesse in de duurzame
              energieoplossingen van SolVue. We zijn enthousiast om u
              een gepersonaliseerde offerte aan te bieden voor de
              installatie van zonnepanelen op het adres{' '}
              {props.data.straat_nummer.trim()}{' '}
              {props.data.postcode_plaats.trim()}.
            </Text>

            <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>
              Vermogen en Rendement
            </Text>

            <Text style={{ marginBottom: 10 }}>
              Het voorgestelde installatieplan omvat{' '}
              {props.data.panelen} hoogwaardige{' '}
              {props.data.panel.name} panelen, elk met een vermogen
              van {props.data.panel.wp} Wp. Hierdoor wordt een
              indrukwekkend totaal vermogen van {props.data.wp}{' '}
              Wattpiek bereikt. Naar verwachting zal deze installatie
              zorgen voor een jaarlijkse energieproductie van{' '}
              {props.data.kwh} kWh.
            </Text>

            <Image
              style={{
                marginTop: 10,
                width: '100%',
                height: 200,
                objectFit: 'stretch',
              }}
              src={props.data.images.legplan}
            />
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
              Stap Over op Zonne-Energie
            </Text>
            <Text style={styles.subText}>
              Een duurzame, rendabele en zorgeloze investering.
            </Text>
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={styles.section.page2.welcomeText}>
              Na een nauwkeurige analyse van uw zonnesysteem, waarbij
              gebruik is gemaakt van luchtfoto's, straatbeelden en
              geavanceerde software, kunnen we bevestigen dat uw dak
              uitstekend geschikt is voor zonnepanelen. Factoren zoals
              de oriëntatie van uw dak, de hellingshoek en mogelijke
              schaduwvorming zijn zorgvuldig overwogen in onze
              berekeningen. Dit zorgt ervoor dat we een op maat
              gemaakt zonnesysteem kunnen aanbieden dat optimaal
              aansluit bij uw situatie.
            </Text>
          </View>

          <View
            style={{
              marginTop: 50,
              backgroundColor: '#f4f4f4',
              padding: '5px 10px',
            }}
          >
            <Text
              style={{
                fontWeight: '700',
                color: '#6AB5E7',
                fontSize: 14,
              }}
            >
              Specificaties van uw dak
            </Text>
          </View>

          <View
            style={{
              fontSize: 11,
              display: 'flex',
              flexDirection: 'row',
              padding: 5,
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <Text style={{ flex: 1 }}>Type dak</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>{props.data.soort_dak}</Text>
          </View>
          <View
            style={{
              fontSize: 11,
              display: 'flex',
              flexDirection: 'row',
              padding: 5,
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <Text style={{ flex: 1 }}>Hellingshoek</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>
              {props.data.helling} graden
            </Text>
          </View>
          <View
            style={{
              fontSize: 11,
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 70,
              padding: 5,
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <Text style={{ flex: 1 }}>Aantal panelen</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>
              {props.data.panelen} panelen
            </Text>
          </View>

          <View
            style={{
              fontSize: 11,
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 70,
            }}
          >
            <Image style={{ flex: 1 }} src={ErvarenMonteur} />

            <View style={{ flex: 1, backgroundColor: '#81b6e763' }}>
              <Text
                style={{
                  marginTop: 7,
                  marginLeft: 20,
                  display: 'block',
                  fontSize: '14',
                  fontWeight: '600',
                  color: '#666',
                }}
              >
                Ervaren SolVue monteurs
              </Text>
              <Text
                style={{
                  marginTop: 5,
                  marginLeft: 20,
                  display: 'block',
                  fontSize: '11',
                  color: '#666',
                  fontWeight: '555',
                  width: '85%',
                }}
              >
                SolVue is trots op ons uitgebreide netwerk van
                in-house opgeleide monteurs, verspreid over heel
                Nederland. Onze deskundige monteurs zijn uitvoerig
                getraind in zonnestroomtechnologieën en voldoen aan de
                strengste normen voor kwaliteit en veiligheid, zodat u
                met vertrouwen kunt investeren in een duurzame
                toekomst.
              </Text>
            </View>
          </View>
          <View></View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View></View>

            {/* <View>
              <Image
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: 250,
                  left: -80,
                }}
                src={HappyGuy}
              />
            </View> */}

            <View></View>
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlockBesparing}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.headerText}>Uw besparing</Text>
              </Text>
              <Text
                style={{
                  flex: 1,
                  fontWeight: 700,
                  fontSize: 16,
                  marginTop: 8,
                  color: '#666',
                }}
              >
                Indicatie Jaarlijkse Opbrengst
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: '#6AB5E7',
                    fontWeight: '600',
                  }}
                >
                  Wat betekent dit voor u.
                </Text>
              </Text>
              <Text
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 21,
                    color: '#6AB5E7',
                    fontWeight: '700',
                  }}
                >
                  {formatEuro(
                    props.data.opbrengst
                      ? props.data.opbrengst
                      : props.data.euro
                  )}
                </Text>
              </Text>
            </View>
          </View>

          <View>
            <Text
              style={{
                marginTop: 14,
                color: '#888',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Installatieproces van uw Zonnesysteem
            </Text>
            <Text style={{ fontSize: 11, marginTop: 5 }}>
              In de onderstaande afbeeldingen wordt geïllustreerd hoe
              de zonnepanelen optimaal op uw woning geplaatst kunnen
              worden voor het beste resultaat. Heeft u specifieke
              ideeën of voorkeuren voor de plaatsing van de panelen?
              We stemmen dit graag samen met u af om aan uw wensen te
              voldoen!
            </Text>
          </View>
          <View>
            <Image
              style={{
                marginTop: 10,
                width: '100%',
                height: 200,
                objectFit: 'stretch',
              }}
              src={props.data.images.legplan}
            />
            <Image
              style={{
                marginTop: 10,
                width: '100%',
                height: 230,
                objectFit: 'contain',
              }}
              src={props.data.images.grafiek}
            />
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
              Uw rendementsverlies schaduw
            </Text>
          </View>

          <View style={{ marginBottom: 20, marginTop: 20 }}>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                color: '#6AB5E7',
              }}
            >
              Schaduw zomer
            </Text>
            <Image
              style={{
                marginTop: 10,
                width: 330,
                height: 250,
                objectFit: 'cover',
              }}
              src={props.data.images.rendementZomer}
            />
          </View>
          <View style={{}}>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                color: '#6AB5E7',
              }}
            >
              Schaduw winter
            </Text>
            <Image
              style={{
                marginTop: 10,
                width: 330,
                height: 250,
                objectFit: 'cover',
              }}
              src={props.data.images.rendementWinter}
            />
          </View>
          {/* <Image
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: 300,
            }}
            src={PointingGuy}
          /> */}
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Uw Offerte</Text>
            <Text style={styles.border.smallOther}></Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <View style={styles.section.page7.topLeft}>
                <Text style={styles.fontBold}>
                  {props.data.voorletter} {props.data.achternaam}
                </Text>
                <Text>{props.data.straat_nummer}</Text>
                <Text style={{ display: 'block', marginBottom: 5 }}>
                  {props.data.postcode_plaats}
                </Text>

                <Text>Offertenummer: {props.data.offerte}</Text>
                <Text>
                  Offerte geldig tot:{' '}
                  {typeof props.data.expiration === 'string'
                    ? props.data.expiration
                    : moment(props.data.expiration).format(
                        'DD-MM-YYYY'
                      )}
                </Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={styles.section.page7.topRight}>
                <Text style={styles.fontBold}>EcoLex</Text>
                <Text>Krammer 8</Text>
                <Text style={{ display: 'block', marginBottom: 5 }}>
                  3232 HE Brielle
                </Text>
                <Text>info@solvue.nl</Text>
              </View>
            </View>
          </View>
          <View>
            <Text
              style={{
                marginTop: 10,
                fontSize: 14,
                fontWeight: 600,
                color: '#555',
              }}
            >
              Offerte
            </Text>
          </View>
          <View style={styles.table}>
            {/* TableHeader */}
            <View style={styles.tableRowHead}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Aantal</Text>
              </View>
              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Omschrijving</Text>
              </View>

              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Prijs</Text>
              </View>
            </View>
            {/* TableContent */}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>
                  {props.data.panelen}
                </Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  {props.data.panel.name} -{' '}
                  {props.data.panel.description}
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            {/* <View style={styles.tableRow}>
              <View style={styles.tableColBig}>
                <Text style={styles.tableCellItalic}>
                  Wij leveren Tier 1 Full Black zonnepanelen. Wegens
                  de veelbewogen markt Ontvangt u altijd 1 van klasse
                  niveau Tier1 Trina, Longi, Jinko of TW Solar met het
                  vermogen tussen de 405 Wp en 430 Wp.
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View> */}

            {console.log(props.data.inverter)}
            {props.data.inverter &&
            props.data.inverter.id === 'HZ20118' ? (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>
                      {props.data.panelen}
                    </Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>
                      {props.data.inverter.name}
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>
                      Enphase Envoy Communicatie Gateway
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>
                      Enphase Q-relais
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              </>
            ) : (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    {props.data.inverter.name}
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>
              </View>
            )}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Nieuwe groep meterkast
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  {props.data.soort_dak} onder constructie
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>
                  {/* {formatEuro(props.data.packPrice)} */}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Klein materiaal</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Technische schouw
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRowBorderBottom}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Installatie- en montagewerkzaamheden
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            {props.data &&
              props.data.extraProducts &&
              props.data.extraProducts.length > 0 &&
              props.data.extraProducts.map((a, i) => {
                return (
                  <View key={i} style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}>{a.aantal}</Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCell}>
                        {a.description}
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        {/* {formatEuro(a.price)} */}
                      </Text>
                    </View>
                  </View>
                );
              })}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>1</Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Aanmelding bij netbeheerder voor teruglevering
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}>
                  {/* {formatEuro(0)} */}
                </Text>
              </View>
            </View>

            {/* Pricing */}
            {props.data.korting.isKorting && (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      Subtotaal excl. btw:
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellRight}>
                      {formatEuro(props.data.korting.exbtw)}
                    </Text>
                  </View>
                </View>

                {props.data.korting.kortingSoort === 'ex'}
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      Korting:
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellKorting}>
                      <Text
                        style={{
                          color: 'green',
                        }}
                      >
                        -{' '}
                        {formatEuro(props.data.korting.discountTotal)}
                      </Text>
                    </Text>
                  </View>
                </View>
              </>
            )}
            <View style={{ borderBottom: '1px solid grey' }}>
              <Text>&nbsp;</Text>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCellRight}>
                  Totaalbedrag excl. BTW:
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}>
                  {props.data.korting.isKorting
                    ? formatEuro(props.data.korting.discountExbtw)
                    : formatEuro(props.data.korting.exbtw)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCellRight}>
                  Btw hoog (0%):
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellKorting}>
                  {!hideBTW ? (
                    <>
                      {props.data.korting.isKorting
                        ? formatEuro(props.data.korting.discountBtw)
                        : formatEuro(props.data.korting.btw_bedrag)}
                    </>
                  ) : (
                    formatEuro(0)
                  )}
                </Text>
              </View>
            </View>
            {!hideBTW ? (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Totaalbedrag incl. BTW.:
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {props.data.korting.isKorting
                        ? formatEuro(
                            props.data.korting.discountInclbtw
                          )
                        : formatEuro(props.data.korting.inclbtw)}
                    </Text>
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Totaalbedrag excl. Btw:
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {props.data.korting.isKorting
                        ? formatEuro(props.data.korting.discountExbtw)
                        : formatEuro(props.data.korting.exbtw)}
                    </Text>
                  </Text>
                </View>
              </View>
            )}
          </View>

          {showPersonal && (
            <View
              style={{
                fontSize: 10,
                marginTop: 10,
                padding: '5px 10px',
                border: '2px solid #6AB5E7',
              }}
            >
              <Text style={{ fontSize: '10px' }}>
                {props.data.personalMessage}
              </Text>
            </View>
          )}

          <View style={{ fontSize: 10, marginTop: 10 }}>
            <Text
              style={{
                fontSize: '12px',
                fontWeight: 700,
                color: '#6AB5E7',
              }}
            >
              Betalingsvoorwaarden
            </Text>
            <Text style={{ marginBottom: 10 }}>
              We vragen een aanbetaling van {props.data.aanbetaling}%
              en het resterende bedrag van{' '}
              {100 - props.data.aanbetaling}% wordt voldaan bij de
              oplevering van het zonnesysteem.
              {props.data.aanbetaling > 0 && (
                <>
                  Na de ondertekening van het contract, zult u binnen
                  enkele dagen een factuur voor de aanbetaling
                  ontvangen. We waarderen het als u deze binnen 7
                  dagen zou kunnen voldoen.
                </>
              )}
            </Text>
            <Text>
              Na uw akkoord op de offerte, zullen we de volgende
              stappen in onze samenwerking zetten! Ons team zal
              spoedig contact met u opnemen om te zorgen voor een
              vlotte en efficiënte voortgang van het proces.
            </Text>
          </View>

          <View style={{ fontSize: 11 }}>
            <Text style={{ marginTop: 5 }}>Voor akkoord:</Text>
            <Text style={{ marginTop: 0 }}>
              Datum:{' '}
              {props.data.signing_date &&
                props.data.signature &&
                props.data.signing_date}
            </Text>
            {props.data.signing_date && props.data.signature && (
              <Image
                style={styles.images.signature}
                src={props.data.signature}
              />
            )}

            <Text
              style={{ marginTop: props.data.signature ? 10 : 60 }}
            >
              {props.data.voornaam} {props.data.achternaam}
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={{ marginTop: 100, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Uw Garanties bij Solvue
            </Text>
            <Text style={{}}>
              Hieronder vindt u een uiteenzetting van de garanties die
              we bieden voor elk component:
            </Text>
            <Text style={{ marginLeft: 15, marginTop: 5 }}>
              - Zonnepanelen:{' '}
              <Text style={{ fontWeight: 600 }}>
                25 jaar garantie
              </Text>
            </Text>
            <Text style={{ marginLeft: 15 }}>
              - Omvormers:{' '}
              <Text style={{ fontWeight: 600 }}>
                25 jaar garantie
              </Text>
            </Text>
            <Text style={{ marginLeft: 15 }}>
              - Installatie:{' '}
              <Text style={{ fontWeight: 600 }}>5 jaar garantie</Text>
            </Text>
          </View>

          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Serviceplan van Solvue
            </Text>
            <Text style={{}}>
              Tot vijf jaar na installatie staan we kosteloos voor u
              klaar, inclusief alle door Solvue verrichte
              werkzaamheden.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Wat volgt na uw akkoord?
            </Text>
            <Text style={{}}>
              Na uw goedkeuring van de offerte, zullen we binnen een
              week contact met u opnemen om de vervolgstappen te
              bespreken.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Verzekering van uw zonnesysteem
            </Text>
            <Text style={{}}>
              Uw nieuwe zonnepanelen vallen onder uw
              opstalverzekering. Wij adviseren u om uw verzekeraar te
              raadplegen voor meer details.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Energiemonitoring
            </Text>
            <Text style={{}}>
              Een stabiele WiFi-verbinding is cruciaal voor de werking
              van de omvormers. Het is uw verantwoordelijkheid om deze
              verbinding te verzorgen, maar onze installateurs staan
              klaar om u te assisteren bij de setup.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Algemene Voorwaarden
            </Text>
            <Text style={{}}>
              U kunt onze algemene voorwaarden raadplegen op onze
              website.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: '#6AB5E7', fontWeight: 600 }}>
              Bescherming van uw persoonlijke gegevens
            </Text>
            <Text style={{}}>
              We hanteren strikte maatregelen om de veiligheid van uw
              persoonlijke gegevens te waarborgen.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default Template1;
