import React from 'react';
import { connect } from 'react-redux';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Firebase, { getUser } from '../helpers/firebase';
import Logo from '../images/logo.png';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class NavBarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showOld: false,
      logo: 'ecolex',
      user: {
        voornaam: '',
        achternaam: '',
      },
    };
  }
  componentDidMount = async () => {
    await this.getLoggedinUser(this.props.user.uid);

    // if (window.location.pathname.includes('ecolex')) {
    //   this.setState({ logo: 'ecolex' });
    // } else {
    //   this.setState({ logo: 'hellozon' });
    // }
  };
  // componentDidUpdate = async (prevProps) => {
  //   if (
  //     this.props.location.pathname !== prevProps.location.pathname
  //   ) {
  //     if (window.location.pathname.includes('ecolex')) {
  //       this.setState({ logo: 'ecolex' });
  //     } else {
  //       this.setState({ logo: 'hellozon' });
  //     }
  //   }
  // };
  logout = async () => {
    await Firebase.auth()
      .signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
  };
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  getLoggedinUser = async (id) => {
    let u = await getUser(id);

    if (u) this.setState({ user: u, showOld: !u.hideOld });
  };
  render() {
    const { user } = this.state;
    const logoOEM = Logo;

    return (
      <div>
        {user && (
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/">
              <div className="logo">
                <img src={logoOEM} />
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {user.role !== 'planner' && (
                  <>
                    {/* <NavItem className="new">
                      <NavLink href={`/`}>Home</NavLink>
                    </NavItem> */}
                    <NavItem className="new">
                      <NavLink href={`/new`}>Nieuwe aanvraag</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href={`/`}>
                        Verstuurde offertes
                      </NavLink>
                    </NavItem>

                    {user.role === 'admin' && (
                      <>
                        {/* <NavItem>
                          <NavLink href="/tools">Tools</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="/installaties">Installaties</NavLink>
                        </NavItem> */}
                      </>
                    )}

                    <NavItem>
                      <NavLink href={`/klanten`}>Klanten</NavLink>
                    </NavItem>

                    {/* {this.state.showOld && (
                      <NavItem>{`/w${this.state.logo}/new`}
                        <NavLink className="old" href="/relaties">
                          Aanvragen (oude systeem)
                        </NavLink>
                      </NavItem>
                    )} */}
                  </>
                )}

                {/* <NavItem>
                <NavLink onClick={() => this.logout()}> Uitloggen</NavLink>
              </NavItem> */}

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Profiel
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem header>
                      {user.voornaam} {user.achternaam}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.logout()}>
                      Uitloggen
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
        )}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(NavBarComponent));
