import React from 'react';

import { saveAs } from 'file-saver';

import moment from 'moment';
import {
  FaEye,
  FaTrash,
  FaFilePdf,
  FaPlus,
  FaFileInvoiceDollar,
  FaEdit,
  FaRegCalendarCheck,
  FaQuestionCircle,
} from 'react-icons/fa';

import { GrStatusUnknown } from 'react-icons/gr';
import { MdArrowBack } from 'react-icons/md';
import {
  getRelatie,
  updateStatus,
  sendSignedOfferte,
  firestore,
  sendOfferteClient,
  addSchouw,
  cancelClient,
} from '../../helpers/firebase';
import {
  loadKlant,
  saveClient,
  saveKlant,
} from '../../helpers/firebase-ecolex';
import {
  saveLog,
  getLogs,
  archiveLog,
} from '../../helpers/firebase2';
import _ from 'lodash';
import { WebView } from '../../components/WebViewer';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  FormGroup,
  Input,
  Container,
  Form,
  Label,
} from 'reactstrap';
import toast, { LoaderIcon } from 'react-hot-toast';
import {
  Link,
  withRouter,
} from 'react-router-dom/cjs/react-router-dom.min';
import { getStatusName, Toasting } from '../../helpers/functions';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import ChangeStatus from '../../components/modals/ChangeStatus';
import status from '../../utils/status';
import StatusBar from '../../components/StatusBar';

class SingleKlantEcoLex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      clientEdit: {},
      currentFile: false,
      status: '',
      showStatus: false,
      showSigning: false,
      ipaddress: null,
      dateTimeVar: moment().format('LLL'),
      sendingMessage: '',
      sending: false,
      showConfetti: false,
      relatie: {},
      currentOfferte: false,
      showLoadIcon: false,
      logs: [],
      showEditForm: false,
      showEditFormAddress: false,
      showEditFormSituation: false,
      showLogForm: false,
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let id = this.props.match.params.id;
    let relatie = await loadKlant(id);
    if (relatie) {
      let logs = await getLogs(id);
      this.setState({ relatie, id: id, logs });
    }
  };
  imageToData = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
  };

  handleDownload = (uri = false) => {
    const data = this.state.currentOfferte;
    let blob = uri ? uri : this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
    return eur;
  };
  showEditStatus = async () => {
    this.setState({
      showStatus: !this.state.showStatus ? true : false,
    });
  };

  cancelClient = async (client) => {
    let reason = prompt(
      `Wat is de reden dat deze klant wilt annuleren?`
    );
    if (reason.length < 10) {
      this.cancelClient(client);
    }
    if (reason.length >= 10) {
      let res = await cancelClient(
        client,
        reason,
        this.props.user.voornaam
      );
      console.log(res);

      // if (res.success) {
      //   // for (const element of offertes) {
      //   //   console.log(element);
      //   //   await updateStatus(element.id, status, "offertesv2", element.status);
      //   // }
      //   // window.location.reload();
      // }
      // if (!res.success && res.error) {
      //   console.log(res.error);
      // }
    }

    // if(res)
    //
    // }
  };

  handleStatusChange = async (e, client) => {
    let status = e.target.value;
    let client_id = client.id;
    let offertes = client.offertes;
    if (status === 'signed') {
      let id = prompt('Welke offerte nr is getekend?');

      if (id) {
        for (const element of offertes) {
          if (element.offerte == id) {
            await updateStatus(
              element.id,
              status,
              'offertesv2',
              element.status
            );
            await updateStatus(
              client_id,
              status,
              'klanten',
              client.status
            );

            window.location.reload();
            // } else {
            //   console.log("update v1");

            //   await updateStatus(element.id, status, "offertes");
            // }
          }
        }
      } else {
        console.log('dont update');
        return false;
      }
    }

    if (status && status !== 'signed') {
      if (offertes.filter((p) => p.status === 'signed').length > 1) {
        alert('Verwijder alle niet relevante offertes.');
        return false;
      }
      await updateStatus(client_id, status, 'klanten', client.status);
      for (const element of offertes) {
        // if (element.panel) {
        //   console.log("update v2");
        await updateStatus(
          element.id,
          status,
          'offertesv2',
          client.status
        );
        // } else {
        //   console.log("update v1");

        //   await updateStatus(element.id, status, "offertes");
        // }
      }
      window.location.reload();
    }

    // for (const element of offertes) {
    //   if (element.status === "send") {
    //     if (element.panel) {
    //       console.log("update v2");
    //       await updateStatus(element.id, status, "offertesv2");
    //     } else {
    //       console.log("update v1");

    //       await updateStatus(element.id, status, "offertes");
    //     }
    //   }
    // }

    // await updateStatus(offerte_id, status, "offertes");
    // window.location.reload();
  };

  toggleshowOfferte = () => {
    this.setState({ currentFile: false });
  };

  deleteOfferte = (data) => {
    confirmAlert({
      title: 'Bevestig keuze',
      message:
        'Weet je zeker dat je de deze offerte wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: async () => {
            firestore
              .collection('offertesv2')
              .doc(data.id)
              .update({ archived: true })
              .then(() => {
                window.location.reload();
              })
              .catch((err) => err);
          },
        },
        {
          label: 'Nee',
          onClick: () => false,
        },
      ],
    });
  };

  archiveAanvraag = (data, id) => {
    // console.log(data, id);

    firestore
      .collection('klanten')
      .doc(id)
      .update({ archived: data.archived ? false : true })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => err);
  };

  sendToClient = async () => {
    let result = await sendOfferteClient(this.state.currentOfferte);
    // console.log(result);
    if (result.success) {
      this.toggleshowOfferte();
      toast.success('Offerte is verstuurd naar de klant.', {
        duration: 4000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        // icon: "👏",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#4caf50',
          secondary: '#fff',
        },

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
    }
  };

  editOfferte = (a = false) => {
    if (a) {
      // console.log(a);
      this.props.history.push(`/offerte/edit/${a.id}`);
    } else {
      // console.log(this.state.currentOfferte);
    }
  };

  showAddLog = () => {
    this.setState({
      showLogForm: !this.state.showLogForm,
    });
  };
  toggleEditForm = () => {
    let editData = _.cloneDeep(this.state.relatie);
    // console.log(editData);
    this.setState({
      showEditForm: !this.state.showEditForm,
      clientEdit: editData,
    });
  };

  handleEdit = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    let data = this.state.clientEdit;

    data.personal[id] = value;

    if (data.offertes && data.offertes.length > 0) {
      data.offertes = data.offertes.map((a) => {
        a[id] = value;
        if (id === 'geslacht') a.aanhef = value;
        return a;
      });
    }

    this.setState({ clientEdit: data });
  };

  handleEditAddress = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    let data = this.state.clientEdit;

    data.address[id] = value;

    if (data.offertes && data.offertes.length > 0) {
      data.offertes = data.offertes.map((a) => {
        a[id] = value;

        return a;
      });
    }

    this.setState({ clientEdit: data });
  };

  handleEditSituation = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    let data = this.state.clientEdit;

    data.situation[id] = value;

    if (data.offertes && data.offertes.length > 0) {
      data.offertes = data.offertes.map((a) => {
        // a[id] = value;
        if (id === 'dakType') {
          a.soort_dak = value;
        }
        return a;
      });
    }

    this.setState({ clientEdit: data });
  };

  toggleEditFormAddress = () => {
    let editData = _.cloneDeep(this.state.relatie);
    // console.log(editData);
    this.setState({
      showEditFormAddress: !this.state.showEditFormAddress,
      clientEdit: editData,
    });
  };

  toggleEditFormSituation = () => {
    let editData = _.cloneDeep(this.state.relatie);
    // console.log(editData);
    this.setState({
      showEditFormSituation: !this.state.showEditFormSituation,
      clientEdit: editData,
    });
  };

  submitEdit = async (e) => {
    console.log(this.state.relatie);
    e.preventDefault();
    let res = await saveClient(this.state.clientEdit);
    if (!res.error) {
      Toasting('Klant/lead succesvol aangepast');
      let logs = await getLogs(this.state.clientEdit.id);
      // console.log(logs);

      this.setState({
        logs,
        showEditForm: false,
        relatie: this.state.clientEdit,
        clientEdit: {},
      });
    }
  };

  submitNewLog = async (e) => {
    e.preventDefault();
    let message = this.state.message;
    let extra = this.state.extra;

    let res = await saveLog(
      this.state.relatie,
      message,
      extra,
      false
    );

    if (res && res.success) {
      let logs = await getLogs(this.state.id);
      this.setState({
        logs,
        message: '',
        extra: '',
        showLogForm: false,
      });
      Toasting('Log succesvol opgeslagen.');
    }
  };

  deleteLog = async (log) => {
    confirmAlert({
      title: 'Bevestig keuze',
      message:
        'Weet je zeker dat je de deze offerte wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: async () => {
            let res = await archiveLog(this.state.relatie.id, log);
            if (res.success) {
              Toasting('Log succesvol verwijderd!');
              let logs = await getLogs(this.state.id);
              this.setState({ logs });
            } else {
              Toasting(
                'Er gaat iets fout. Probeer het later nog eens!',
                true
              );
            }
          },
        },
        {
          label: 'Nee',
          onClick: () => false,
        },
      ],
    });
  };
  showKlantView = (data) => {
    window.open(`/voorstel/${data.id}`, '_blank');
  };

  sendToPlanning = async (offerte) => {
    // console.log(offerte);
    let confirm = await addSchouw(offerte);
    if (confirm) {
      Toasting('Deze klant word ingepland voor de schouw.');
    }
  };

  updateStatus = (offerte, status) => {
    console.log(offerte, status, 'update status');
  };

  transferOwnership = (user, relatie) => {
    let oldEnough = this.isOld(relatie);
    // if (oldEnough) {
    relatie.userID = user;
    relatie.offertes = relatie.offertes.map((a) => {
      a.agent = user;
      return a;
    });
    saveKlant(relatie.id, relatie);
    // }
    // if (user.owner === "Fi4xkYzP2MeCxpeAfWONtsBq8Pk1") {
    //
    // }
  };

  isOld = (relatie) => {
    const thirtyDaysAgo = moment().subtract(30, 'days');
    console.log(
      thirtyDaysAgo.format('DD-MM-yyyy'),
      '30 dagen geleden'
    );
    let old = false;

    // Check if relatie.createAt is older than 30 days
    if (typeof relatie.createdAt === 'object') {
      relatie.createdAt = relatie.createdAt.toDate();
    }
    const createAtDate = moment(relatie.createdAt);
    console.log(createAtDate.format('DD-MM-yyyy'), 'created at');

    if (createAtDate.isBefore(thirtyDaysAgo)) {
      old = true; // Not old enough
    }

    // Check if any a.expiration is older than 30 days
    for (const a of relatie.offertes) {
      if (typeof a.expiration === 'object') {
        a.expiration = a.expiration.toDate();
      }
      const expirationDate = moment(a.expiration);
      if (expirationDate.isBefore(thirtyDaysAgo)) {
        old = true; // Not old enough
      }
    }
    if (!old) {
      alert('Klant is nog geen 30 dagen oud. Probeer een andere.');
    }
    return old; // Both createAt and all a.expiration are older than 30 days
  };

  render() {
    const { relatie, logs } = this.state;
    // console.log(relatie);
    return (
      <div className="ecolex">
        {!_.isEmpty(relatie) && (
          <>
            <Container className="head-container mt-3">
              <Row>
                <Col xs="5">
                  <Button
                    color="alt"
                    onClick={() => this.props.history.push('/')}
                  >
                    <MdArrowBack /> terug
                  </Button>

                  <div className="owner block">
                    {relatie.userID && relatie.userID.voornaam && (
                      <div className="owner-agent">
                        <div className="owner-label">Agent</div>
                        {relatie.userID.voornaam}{' '}
                        {relatie.userID.achternaam}
                      </div>
                    )}
                    {this.props.user.role === 'admin' && (
                      <>
                        {<div className="seperator"> | </div>}
                        {relatie.userID && relatie.userID.company && (
                          <div className="owner-company">
                            <div className="owner-label">Bedrijf</div>
                            {relatie.userID.bedrijfsnaam}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* {relatie.userID.id !== this.props.user.id && (
                    <Button
                      color="primary"
                      onClick={() =>
                        this.transferOwnership(
                          this.props.user,
                          relatie
                        )
                      }
                    >
                      Transfer lead
                    </Button>
                  )} */}
                </Col>
                <Col xs="7">
                  {relatie.status && !relatie.statusNew && (
                    <div className="status-container" color="alt">
                      {this.state.showStatus && (
                        <span className="form-group mb-0 mr-3">
                          <Input
                            id="status"
                            name="status"
                            onInput={(e) =>
                              this.handleStatusChange(e, relatie)
                            }
                            type="select"
                          >
                            <option>-----</option>
                            <option value="backoffice">
                              Backoffice
                            </option>
                            <option value="legplan">
                              Herzien legplan
                            </option>
                            <option value="ready">
                              Klaar om te versturen
                            </option>
                            <option value="send">
                              Verzonden naar klant
                            </option>
                            <option value="signed">Getekend</option>
                            <option value="qcyes">QC positief</option>
                            <option value="qcno">QC negatief</option>
                            <option value="schouw1">
                              Schouw inplannen
                            </option>
                            <option value="schouw2">
                              Schouw ingepland
                            </option>
                            <option value="schouw3">
                              Schouw uitgevoerd
                            </option>
                            <option value="installatie1">
                              Installatie inplannen
                            </option>
                            <option value="installatie2">
                              Installatie ingepland
                            </option>
                            <option value="installatie3">
                              Installatie uitgevoerd
                            </option>

                            <option value="cancelled">
                              Geannuleerd
                            </option>
                          </Input>
                        </span>
                      )}

                      <span
                        className="status-text"
                        onClick={() => this.showEditStatus()}
                      >
                        Status:{' '}
                        <span>{getStatusName(relatie.status)}</span>
                      </span>
                    </div>
                  )}

                  {relatie.statusNew && (
                    <>
                      {relatie.statusNew === 'CANCELLED' ? (
                        <div className="cancelled-client">
                          Geannuleerd
                        </div>
                      ) : (
                        <StatusBar scrollToItemID={relatie.statusNew}>
                          {Object.keys(status).map((a) => {
                            return (
                              <div
                                key={a}
                                className={
                                  relatie.statusNew === a
                                    ? 'active'
                                    : ''
                                }
                                id={a}
                              >
                                {status[a]}
                              </div>
                            );
                          })}
                        </StatusBar>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
            <Row className="form details klant">
              <Col xs="4">
                <div className="left-container">
                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Klant Informatie</h3>
                      <span className="align-right">
                        {relatie.personal.zakelijkOf}
                      </span>
                    </div>

                    <div className="block-content personal">
                      {relatie.statusNew !== 'CANCELLED' && (
                        <div className="status-block cancel">
                          <div
                            onClick={() =>
                              this.cancelClient(this.state.relatie)
                            }
                          >
                            Klant annuleren
                          </div>
                        </div>
                      )}

                      <div className="status-block">
                        {!this.state.relatie.archived && (
                          <>
                            <div
                              className="text-link"
                              onClick={() =>
                                this.archiveAanvraag(
                                  this.state.relatie,
                                  this.state.id
                                )
                              }
                            >
                              <span
                                className="text-link"
                                onClick={() =>
                                  this.archiveAanvraag(
                                    this.state.relatie,
                                    this.state.id
                                  )
                                }
                              >
                                Archiveren{' '}
                                <small>(Uit overzicht halen)</small>
                              </span>
                            </div>
                          </>
                        )}

                        {this.state.relatie.archived && (
                          <div>
                            <span
                              className="text-link mb-3"
                              onClick={() =>
                                this.archiveAanvraag(
                                  this.state.relatie,
                                  this.state.id
                                )
                              }
                            >
                              Terughalen{' '}
                              <small>
                                (terug in overzicht halen)
                              </small>
                            </span>
                            <br />
                            <span className="error">
                              Gearchiveerd
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="content-group">
                        <h4>
                          Persoonsgegevens{' '}
                          <div className="edit-link">
                            <span
                              className="text-link small"
                              onClick={() => this.toggleEditForm()}
                            >
                              <FaEdit /> edit
                            </span>
                          </div>
                        </h4>

                        <span className="block-item no-border">
                          Geslacht:{' '}
                          <span className="align-right">
                            {relatie.personal.geslacht}
                          </span>
                        </span>
                        <span className="block-item">
                          Voornaam:{' '}
                          <span className="align-right">
                            {relatie.personal.voornaam}
                          </span>
                        </span>
                        <span className="block-item">
                          Achternaam:{' '}
                          <span className="align-right">
                            {relatie.personal.achternaam}
                          </span>
                        </span>

                        <span className="block-item">
                          Email:
                          <span
                            style={{ fontSize: '14px' }}
                            className="align-right"
                          >
                            {relatie.personal.email}
                          </span>
                        </span>

                        <span className="block-item">
                          Telefoonnummer
                          <span className="align-right">
                            {relatie.personal.telefoonnummer}
                          </span>
                        </span>
                      </div>
                      <div className="content-group">
                        <h4>
                          Addressgegevens{' '}
                          <div className="edit-link">
                            <span
                              className="text-link small"
                              onClick={() =>
                                this.toggleEditFormAddress()
                              }
                            >
                              <FaEdit /> edit
                            </span>
                          </div>
                        </h4>

                        <span className="block-item no-border">
                          Straat:
                          <span className="align-right">
                            {relatie.address.straat}{' '}
                            {relatie.address.huisnummer}{' '}
                            {relatie.address.toevoeging}
                          </span>
                        </span>

                        <span className="block-item">
                          Postcode
                          <span className="align-right">
                            {relatie.address.postcode}
                          </span>
                        </span>
                        <span className="block-item">
                          Plaats
                          <span className="align-right">
                            {relatie.address.stad}
                          </span>
                        </span>
                      </div>

                      <div className="content-group">
                        <h4>
                          Situatie{' '}
                          <div className="edit-link">
                            <span
                              className="text-link small"
                              onClick={() =>
                                this.toggleEditFormSituation()
                              }
                            >
                              <FaEdit /> edit
                            </span>
                          </div>
                        </h4>
                        <span className="block-item no-border">
                          Soort dak
                          <span className="align-right">
                            {relatie.situation.dakType}
                          </span>
                        </span>

                        <span className="block-item ">
                          Geschatte kWh
                          <span className="align-right">
                            {relatie.situation.verbruik} kWh
                          </span>
                        </span>
                        <span className="block-item">
                          geschatte panelen
                          <span className="align-right">
                            {(
                              relatie.situation.verbruik / 410
                            ).toFixed(0)}
                          </span>
                        </span>
                      </div>

                      <div className="content-group">
                        <span className="block-item mmt-3">
                          <b>Omschrijving</b>
                          <span style={{ display: 'block' }}>
                            {relatie.situation.description.length > 0
                              ? relatie.situation.description.length >
                                0
                              : '-'}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* end block */}

                  {/* <div className="information-block block-main">
                    <div className="block-header">
                      <h3>Documenten</h3>
                    </div>
                    <div className="block-content">
                      <ul>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/panelen.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download datasheet zonnepanelen
                          </a>
                        </li>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/20221108-Deye-microomvormers.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download datasheet micro-omvormers
                          </a>
                        </li>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://www.ecolex.nl/wp-content/uploads/2023/09/ecolex-algemene-voorwaarden.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download algemene voorwaarden
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col xs="8">
                {/* start block */}
                <div className="information-block block-main">
                  <div className="block-header">
                    <h3>Offertes</h3>
                    <span
                      className="add-button"
                      onClick={() =>
                        this.props.history.push(
                          `/offerte/nieuw/${relatie.id}`
                        )
                      }
                    >
                      <FaPlus />
                    </span>
                  </div>
                  <div className="block-content table-block offertes">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>#</th>

                          <th>Offertedatum</th>
                          <th>Geldig tot</th>
                          <th>Prijs</th>
                          <th>Panelen</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {relatie.offertes.length > 0 &&
                          relatie.offertes.map((a, i) => {
                            // console.log(a);
                            return (
                              <tr
                                key={a.id}
                                className={`${a.status} ${
                                  a.archived && 'gearchiveerd'
                                }`}
                              >
                                <th
                                  scope="row"
                                  className="offerte-nummer"
                                >
                                  {a.offerte} <br />
                                  {a.archived && 'gearchiveerd'}
                                </th>
                                <td className="date">
                                  {moment(a.today).fromNow()}
                                  <br />
                                  <span>
                                    {moment(a.today).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </span>
                                </td>
                                <td className="date">
                                  {moment(a.expiration).fromNow()}
                                  <br />
                                  <span>
                                    {' '}
                                    {moment(a.expiration).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      a.korting && a.korting.isKorting
                                        ? 'discount-price'
                                        : ''
                                    }`}
                                  >
                                    {this.formatEuro(a.exbtw)}
                                  </span>
                                  {a.korting &&
                                    a.korting.isKorting && (
                                      <b>
                                        {a.korting.isKorting && (
                                          <>
                                            {a.korting.discountExbtw
                                              ? this.formatEuro(
                                                  a.korting
                                                    .discountExbtw
                                                )
                                              : this.formatEuro(
                                                  a.korting.exbtw
                                                )}
                                          </>
                                        )}
                                      </b>
                                    )}
                                </td>
                                <td>{a.panelen}</td>
                                {/* <td>
                                <div id="progress-bar-container">
                                  <div className="progress-bar-child progress">
                                    <div
                                      className="progress-bullet"
                                      style={{
                                        left: `${a.change - 14}%`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </td> */}
                                <td width="10px">
                                  {a.inverter ? (
                                    <>{getStatusName(a.status)}</>
                                  ) : (
                                    <>Concept</>
                                  )}
                                </td>
                                <td
                                  width="10px"
                                  className="action-col"
                                >
                                  <div className="actions">
                                    {a.inverter && (
                                      <div
                                        onClick={() =>
                                          this.showKlantView(a)
                                        }
                                        className="action view"
                                      >
                                        <div className="action-item">
                                          <FaEye />{' '}
                                          {this.state
                                            .showLoadIcon && (
                                            <span className="loader-icon">
                                              <LoaderIcon />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {!a.archived && (
                                      <>
                                        <div
                                          className="action edit-klant"
                                          onClick={() =>
                                            this.editOfferte(a)
                                          }
                                        >
                                          <div className="action-item">
                                            <FaEdit />
                                          </div>
                                        </div>
                                        <div
                                          className="action delete"
                                          onClick={() =>
                                            this.deleteOfferte(a)
                                          }
                                        >
                                          <div className="action-item">
                                            <FaTrash />
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {this.props.user &&
                                      this.props.user.role ===
                                        'admin' && (
                                        <>
                                          {a.inverter && (
                                            <>
                                              {/* <div
                                                className="action view-invoice"
                                                onClick={() =>
                                                  window.open(
                                                    `/factuur/create/${a.id}`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                <div className="action-item">
                                                  <FaFileInvoiceDollar />
                                                </div>
                                              </div> */}
                                              {/* <div
                                                className="action view-planning"
                                                onClick={() =>
                                                  this.sendToPlanning(
                                                    a
                                                  )
                                                }
                                              >
                                                <div className="action-item">
                                                  <FaRegCalendarCheck />
                                                </div>
                                              </div> */}
                                              {/* <div
                                                  className="action edit-status"
                                                  onClick={() =>
                                                    this.editStatus(a)
                                                  }
                                                >
                                                  <div className="action-item">
                                                    <FaQuestionCircle color="white" />
                                                  </div>
                                                </div>*/}
                                            </>
                                          )}
                                        </>
                                      )}
                                  </div>
                                </td>
                                {/* <div className="status-button">
                                      <ChangeStatus
                                        client={relatie}
                                        currentStatus={a.status}
                                        offerte={a}
                                        updateStatus={this.updateStatus}
                                      />
                                    </div> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    {relatie.offertes.filter((a) => !a.archived)
                      .length === 0 && (
                      <span className="empty-message">
                        Er zijn geen offertes gevonden.{' '}
                        <Link to={`/offerte/nieuw/${relatie.id}`}>
                          Nieuwe offerte aanmaken
                        </Link>
                      </span>
                    )}
                    {/* {relatie.offertes.map((a, i) => {
                      // console.log(a);
                      return <div key={i}></div>;
                    })} */}
                  </div>
                </div>
                {/* end block */}

                {/* start block logs*/}
                <div className="information-block">
                  <div className="block-header">
                    <h3>Logboek</h3>
                    <div className="align-right">
                      <div
                        className="add-button"
                        onClick={() => this.showAddLog()}
                      >
                        <FaPlus />
                      </div>
                    </div>
                  </div>
                  <div className="block-content logs">
                    {logs.length > 0 &&
                      logs.map((a, i) => {
                        return (
                          <Row key={a.id} className="log-item">
                            <Col xs="7">
                              <span className="message">
                                {a.message}
                              </span>
                            </Col>
                            <Col
                              xs="4"
                              style={{ textAlign: 'right' }}
                            >
                              <span className="date">
                                {moment(a.date).fromNow()}
                              </span>
                            </Col>
                            <Col
                              xs="1"
                              style={{ textAlign: 'right' }}
                            >
                              <span
                                onClick={() => this.deleteLog(a)}
                                className="delete"
                              >
                                <FaTrash />
                              </span>
                            </Col>
                            <Col xs="12">
                              <span className="extra">{a.extra}</span>
                            </Col>
                          </Row>
                        );
                      })}
                  </div>
                </div>
                {/* end block */}
              </Col>
            </Row>
          </>
        )}

        {this.state.currentFile && (
          <Modal
            className="modal-preview"
            isOpen={this.state.currentFile ? true : false}
            toggle={() => this.toggleshowOfferte()}
          >
            <ModalHeader toggle={() => this.toggleshowOfferte()}>
              Offerte Bekijken
            </ModalHeader>
            {/* <ModalHeader>Offerte ondertekenen</ModalHeader> */}
            <ModalBody>
              <div className="modal-header-buttons">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => this.toggleshowOfferte()}
                >
                  Sluiten
                </Button>
                <Button
                  size="sm"
                  color="warning"
                  onClick={() => this.editOfferte()}
                >
                  Pas offerte aan
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => this.handleDownload()}
                >
                  Downloaden als Docx
                </Button>
                {/* <Button
                  onClick={() => this.sendToBackOffice()}
                  size="sm"
                  color="secondary"
                >
                  Opslaan en later versturen
                </Button>  */}
                <Button
                  size="sm"
                  onClick={() => this.sendToClient()}
                  color="primary"
                >
                  Versturen naar klant
                </Button>
                <Button
                  size="sm"
                  onClick={() =>
                    this.showKlantView(this.state.currentOfferte)
                  }
                  color="success"
                >
                  Bekijken als klant
                </Button>
              </div>
              <WebView file={this.state.currentFile} />
            </ModalBody>
          </Modal>
        )}

        {this.state.showLogForm && (
          <Modal
            className="modal-preview log"
            isOpen={this.state.showLogForm ? true : false}
            toggle={() => this.showAddLog()}
          >
            <ModalHeader toggle={() => this.showAddLog()}>
              Log toevoegen
            </ModalHeader>

            <ModalBody>
              <Form onSubmit={(e) => this.submitNewLog(e)}>
                <FormGroup>
                  <Label>Bericht</Label>
                  <Input
                    required
                    onChange={(e) =>
                      this.setState({ message: e.target.value })
                    }
                    id="message"
                    type="text"
                    placeholder="Klant twijfelt"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Toelichting <small>optioneel</small>
                  </Label>

                  <Input
                    onChange={(e) =>
                      this.setState({ extra: e.target.value })
                    }
                    id="extra"
                    type="textarea"
                    placeholder=""
                  />
                </FormGroup>
                <Button type="submit" color="primary">
                  Opslaan
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        )}
        {this.state.showEditForm && this.state.clientEdit && (
          <Modal
            className="modal-preview log"
            isOpen={this.state.showEditForm ? true : false}
            toggle={() => this.toggleEditForm()}
          >
            <ModalHeader toggle={() => this.toggleEditForm()}>
              Gegevens aanpassen
            </ModalHeader>

            <ModalBody>
              <Form onSubmit={(e) => this.submitEdit(e)}>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup className="form-group">
                      <Label for="huurKoop">Koop of lease</Label>
                      <Input
                        id="huurKoop"
                        name="huurKoop"
                        onInput={(e) => this.handleEdit(e)}
                        type="select"
                        defaultValue={
                          this.state.clientEdit.personal.huurKoop
                        }
                      >
                        <option>-----</option>
                        <option value="Koop">Koop</option>
                        <option value="Lease">Lease</option>
                        <option value="LeaseKoop">
                          Lease proberen, anders koop
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup className="form-group">
                      <Label for="zakelijkOf">
                        Particulier of zakelijk{' '}
                      </Label>
                      <Input
                        id="zakelijkOf"
                        name="zakelijkOf"
                        onInput={(e) => this.handleEdit(e)}
                        defaultValue={
                          this.state.clientEdit.personal.zakelijkOf
                        }
                        type="select"
                      >
                        <option>-----</option>
                        <option value="Particulier">
                          Particulier
                        </option>
                        <option value="Zakelijk">Zakelijk</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <h3>Klantgegevens</h3> */}

                <Row>
                  <Col xs="12" md="3">
                    <FormGroup className="form-group">
                      <Label for="geslacht">Geslacht</Label>
                      <Input
                        id="geslacht"
                        name="geslacht"
                        defaultValue={
                          this.state.clientEdit.personal.geslacht
                        }
                        onInput={(e) => this.handleEdit(e)}
                        type="select"
                      >
                        <option>-----</option>
                        <option value="Man">Man</option>
                        <option value="Vrouw">Vrouw</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="form-group">
                      <Label for="voornaam">Voornaam</Label>
                      <Input
                        required
                        onInput={(e) => this.handleEdit(e)}
                        defaultValue={
                          this.state.clientEdit.personal.voornaam
                        }
                        id="voornaam"
                        name="voornaam"
                        placeholder="voornaam"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="5">
                    <FormGroup className="form-group">
                      <Label for="achternaam">Achternaam</Label>
                      <Input
                        required
                        onInput={(e) => this.handleEdit(e)}
                        id="achternaam"
                        name="achternaam"
                        defaultValue={
                          this.state.clientEdit.personal.achternaam
                        }
                        placeholder="achternaam"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" md="6">
                    <FormGroup className="form-group">
                      <Label for="email">Email</Label>
                      <Input
                        required
                        onInput={(e) => this.handleEdit(e)}
                        id="email"
                        name="email"
                        placeholder="email"
                        defaultValue={
                          this.state.clientEdit.personal.email
                        }
                        type="email"
                        // onBlur={(e) => this.checkEmailExists(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup className="form-group">
                      <Label for="telefoonnummer">
                        Telefoonnummer
                      </Label>
                      <Input
                        required
                        onInput={(e) => this.handleEdit(e)}
                        id="telefoonnummer"
                        name="telefoonnummer"
                        defaultValue={
                          this.state.clientEdit.personal
                            .telefoonnummer
                        }
                        placeholder="telefoonnummer"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button type="submit" color="primary">
                  Opslaan
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        )}
        {this.state.showEditFormAddress &&
          this.state.clientEdit &&
          this.state.clientEdit.address && (
            <Modal
              className="modal-preview log"
              isOpen={this.state.showEditFormAddress ? true : false}
              toggle={() => this.toggleEditFormAddress()}
            >
              <ModalHeader
                toggle={() => this.toggleEditFormAddress()}
              >
                Adresgegevens aanpassen
              </ModalHeader>

              <ModalBody>
                <Form onSubmit={(e) => this.submitEdit(e)}>
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup className="form-group">
                        <Label for="straat">Straat</Label>
                        <Input
                          required
                          onInput={(e) => this.handleEditAddress(e)}
                          defaultValue={
                            this.state.clientEdit.address.straat
                              ? this.state.clientEdit.address.straat
                              : ''
                          }
                          id="straat"
                          name="straat"
                          placeholder="straat"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup className="form-group">
                        <Label for="huisnummer">Huisnummer</Label>
                        <Input
                          required
                          onInput={(e) => this.handleEditAddress(e)}
                          defaultValue={
                            this.state.clientEdit.address.huisnummer
                          }
                          id="huisnummer"
                          name="huisnummer"
                          placeholder="huisnummer"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup className="form-group">
                        <Label for="toevoeging">Toevoeging</Label>
                        <Input
                          onInput={(e) => this.handleEditAddress(e)}
                          defaultValue={
                            this.state.clientEdit.address.toevoeging
                          }
                          id="toevoeging"
                          name="toevoeging"
                          placeholder="toevoeging"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <h3>Klantgegevens</h3> */}

                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup className="form-group">
                        <Label for="postcode">Postcode</Label>
                        <Input
                          required
                          id="postcode"
                          name="postcode"
                          defaultValue={
                            this.state.clientEdit.address.postcode
                          }
                          onInput={(e) => this.handleEditAddress(e)}
                          type="input"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                      <FormGroup className="form-group">
                        <Label for="stad">Stad</Label>
                        <Input
                          required
                          onInput={(e) => this.handleEditAddress(e)}
                          defaultValue={
                            this.state.clientEdit.address.stad
                          }
                          id="stad"
                          name="stad"
                          placeholder="stad"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button type="submit" color="primary">
                    Opslaan
                  </Button>
                </Form>
              </ModalBody>
            </Modal>
          )}

        {this.state.showEditFormSituation &&
          this.state.clientEdit &&
          this.state.clientEdit.situation && (
            <Modal
              className="modal-preview log"
              isOpen={this.state.showEditFormSituation ? true : false}
              toggle={() => this.toggleEditFormSituation()}
            >
              <ModalHeader
                toggle={() => this.toggleEditFormSituation()}
              >
                Situatie aanpassen
              </ModalHeader>

              <ModalBody>
                <Form onSubmit={(e) => this.submitEdit(e)}>
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup className="form-group">
                        <Label for="dakType">Soort dak</Label>
                        <Input
                          id="dakType"
                          name="dakType"
                          onInput={(e) => this.handleEditSituation(e)}
                          defaultValue={
                            this.state.clientEdit.situation.dakType
                          }
                          type="select"
                        >
                          <option>-----</option>
                          <option value="Plat dak">Plat dak</option>
                          <option value="Schuin dak">
                            Schuin dak
                          </option>
                          <option value="Schuin || Plat">
                            Schuin en plat
                          </option>
                          <option value="Weet ik nog niet">
                            Weet ik nog niet
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button type="submit" color="primary">
                    Opslaan
                  </Button>
                </Form>
              </ModalBody>
            </Modal>
          )}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
    // products: state.products,
  };
};

export default connect(mapStateToProps)(
  withRouter(SingleKlantEcoLex)
);
